<template>
  <el-dialog
    title="请填写申诉理由"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :distinguishCancelAndClose="true"
    :showClose="false"
    @close="close"
    width="666px"
  >
    <el-input
      type="textarea"
      placeholder="请详细填写申诉理由"
      v-model="desc"
      :rows="4"
      maxlength="300"
      show-word-limit
      style="margin: 12px 0; margin-bottom: 24px"
    >
    </el-input>
    <el-upload
      :action="$upLoad"
      list-type="picture-card"
      :limit="9"
      multiple
      :accept="acceptArr"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <div style="display: flex; margin-top: 42px; align-items: baseline">
      <i
        class="el-icon-success"
        v-if="xystate"
        style="font-size: 15px; color: var(--theme-color)"
        @click="xuanzhong"
      ></i>
      <div
        v-else
        style="
          width: 13px;
          height: 13px;
          border-radius: 13px;
          border: #c9c9c9 1px solid;
        "
        @click="xuanzhong"
      ></div>
      <div style="margin-left: 6px; color: #64b6a8">
        <span style="color: #666666">申诉即默认同意</span>
        <span
          style="color: var(--theme-color); cursor: pointer"
          @click="toArticle(1)"
          >《用户协议》</span
        >
        <span
          style="color: var(--theme-color); cursor: pointer"
          @click="toArticle(2)"
          >《隐私政策》</span
        >
      </div>
    </div>
    <div
      style="
        color: #ffffff;
        font-size: 18px;
        width: 100%;
        height: 44px;
        background-color: var(--theme-color);
        text-align: center;
        line-height: 44px;
        border-radius: 40px;
        margin: 12px 0;
        cursor: pointer;
      "
      @click="subJu"
    >
      立即申诉
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "complaint",
  props: ["bUserId"],

  data() {
    return {
      // this.$imgSuffixArr.join(',')
      acceptArr: this.$imgSuffixArr,
      xystate: true,
      dialogVisible: true,
      desc: "",
      agreement: true,
      juBaoList: [
        {
          type: "车源不存在",
          show: false,
        },
        {
          type: "车源信息不真实",
          show: false,
        },
        {
          type: "车源图片不真实",
          show: false,
        },
        {
          type: "其他",
          show: false,
        },
      ],
      dialogImageUrl: [],
      dialogVisible: true,
      imgList: [],
      data: {
        // userId:'',
        // bUserId:'',
        // reportType:'',
        // houseId:''
      },
    };
  },
  watch: {
    dialogVisible(newV, oldV) {
      if (!newV) {
        this.setShowComplaint(false);
      }
    },
  },
  created() {
    console.log("11");
  },

  methods: {
    ...mapMutations(["setShowComplaint"]),

    juLei(type) {
      this.juBaoList.forEach((e) => {
        e.show = false;
        if (e.type == type) {
          e.show = true;
        }
      });
    },
    xuanzhong() {
      if (this.xystate) {
        this.xystate = false;
      } else {
        this.xystate = true;
      }
    },
    subJu() {
      if (!this.xystate) {
        this.myErrorMessage("请阅读并同意相关协议");
        return;
      }

      if (this.desc.trim().length == 0) {
        this.myErrorMessage("请填写申诉理由！");
        return;
      }
      this.data.reportContent = this.desc;
      if (this.imgList.length > 0) {
        let image = "";
        this.imgList.forEach((e, index) => {
          if (index == this.imgList.length - 1) {
            image += e.path;
          } else {
            image += e.path + "|";
          }
        });
        console.log(image);
        this.data.image = image;
      } else {
        this.data.image = "";
      }
      this.data.complainantId = localStorage.getItem("tempId");
      // console.log(this.data);
      // this.setShowComplaint(false)
      // return
      this.$postHttp("/api/mobile/public/accountAppeal", this.data).then(
        (data) => {
          if (data.code == 200) {
            this.mySuccessMessage(res.msg);

            this.setShowComplaint(false);
          }
        }
      );
    },
    handleRemove(file, fileList) {
      for (var i = 0; i < this.imgList.length; i++) {
        if (this.imgList[i].id == file.uid) {
          this.imgList.splice(i, 1);
        }
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess(response, file, fileList) {
      this.imgList.push({
        id: file.uid,
        path: response.data[0].url,
      });
    },
    handleClose(done) {
      done();
      // this.$confirm("关闭弹窗即退出登录,是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "继续填写申诉表单",
      //   type: "warning",
      // })
      //   .then((_) => {
      //     done();
      //     this.logout();
      //   })
      //   .catch((_) => {});
    },
    close() {
      this.setShowComplaint(false);
    },
    logout() {
      // localStorage.removeItem("userInfo");
      // localStorage.removeItem("contrastIdList");
      // localStorage.removeItem("confirm");
      // localStorage.removeItem("tempId");
      // this.$router.push({
      //   path: "/",
      // });
      // this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 20px 20px 20px;
}
::v-deep {
  .el-upload__input {
    display: none !important;
  }
}
</style>
