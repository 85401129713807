<template>
  <div class="login">
    <div class="zbc" v-if="showLogin">
      <div class="login-wrap">
        <img
          src="@/assets/user/login-close.png"
          alt=""
          class="login-close"
          srcset=""
          @click="showLogin = false"
        />
        <h2
          class="login-text"
          :class="
            activeName == 'forget' || pageStatus == 'register' ? 'mb' : ''
          "
        >
          {{
            activeName == "forget"
              ? "找回密码"
              : pageStatus == "register"
              ? "欢迎注册数字乡村"
              : "欢迎登录数字乡村"
          }}
        </h2>

        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
          v-if="pageStatus == 'login' && activeName != 'forget'"
        >
          <el-tab-pane label="密码登录" name="isPasswordLogin"></el-tab-pane>
          <el-tab-pane
            label="验证码登录"
            name="isVerifyCodeLogin"
          ></el-tab-pane>
        </el-tabs>

        <div class="input-wrap">
          <el-input
            v-model="account"
            oninput="if(value.length>11)value=value.slice(0,11)"
            type="number"
            :placeholder="
              activeName != 'forget' && pageStatus == 'register'
                ? '请输入手机号码'
                : '请输入账号'
            "
          ></el-input>
          <img
            v-if="'isPasswordLogin' == activeName"
            src="@/assets/user/login-user.png"
            class="login-img"
            alt=""
            srcset=""
          />
          <img
            v-if="'isVerifyCodeLogin' == activeName || 'forget' == activeName"
            src="@/assets/user/login-phone.png"
            class="login-img"
            alt=""
            srcset=""
          />
        </div>
        <template v-if="pageStatus == 'login'">
          <div class="input-wrap" v-if="activeName == 'isPasswordLogin'">
            <el-input
              v-model="password"
              type="password"
              maxlength="16"
              show-password
              placeholder="请输入密码"
            ></el-input>
            <img
              v-if="'isPasswordLogin' == activeName"
              src="@/assets/user/login-pass.png"
              class="login-img"
              alt=""
              srcset=""
            />
          </div>
          <div
            class="input-wrap verify-code-login"
            v-if="activeName == 'isPasswordLogin'"
          >
            <el-input
              v-model="inputVerifyCode"
              maxlength="6"
              oninput="if(value.length>6)value=value.slice(0,6)"
              type="number"
              placeholder="请输入验证码"
            ></el-input>
            <img
              style="left: 12px"
              src="@/assets/user/login-safe.png"
              class="login-img"
              alt=""
              srcset=""
            />
            <div class="register-vcerify-code" @click="refreshCode">
              <SecurityCode :identifyCode="identifyCode"></SecurityCode>
            </div>
          </div>
          <div
            class="input-wrap verify-code-login"
            v-if="activeName == 'isVerifyCodeLogin' || activeName == 'forget'"
          >
            <el-input
              v-model="verifyCode1"
              placeholder="请输入验证码"
              type="number"
              oninput="if(value.length>6)value=value.slice(0,6)"
              v-if="'isVerifyCodeLogin' == activeName"
            ></el-input>
            <el-input
              v-model="verifyCode2"
              type="number"
              oninput="if(value.length>6)value=value.slice(0,6)"
              placeholder="请输入验证码"
              v-if="'forget' == activeName"
            ></el-input>
            <img
              v-if="'isVerifyCodeLogin' == activeName || 'forget' == activeName"
              style="left: 12px"
              src="@/assets/user/login-safe.png"
              class="login-img"
              alt=""
              srcset=""
            />
            <el-button
              plain
              class="send-vcerify-code"
              v-if="'forget' != activeName"
              @click="sendVcerifyCode2"
              >{{ time == -1 ? "发送验证码" : time + "s后重新获取" }}
            </el-button>
            <el-button
              plain
              class="send-vcerify-code"
              v-if="'forget' == activeName"
              @click="sendVcerifyCode2"
              >{{ time2 == -1 ? "发送验证码" : time2 + "s后重新获取" }}
            </el-button>
          </div>
          <div class="input-wrap" v-if="activeName == 'forget'">
            <el-input
              v-model="forgetPassword"
              type="password"
              maxlength="16"
              show-password
              placeholder="请输入密码"
            ></el-input>
            <img
              src="@/assets/user/login-pass.png"
              class="login-img"
              alt=""
              srcset=""
            />
          </div>
          <div class="input-wrap" v-if="activeName == 'forget'">
            <el-input
              v-model="forgetRePassword"
              type="password"
              maxlength="16"
              show-password
              placeholder="请确认输入密码"
            ></el-input>
            <img
              src="@/assets/user/login-pass.png"
              class="login-img"
              alt=""
              srcset=""
            />
          </div>
        </template>
        <template v-if="pageStatus == 'register'">
          <div class="input-wrap verify-code-login">
            <el-input
              v-model="registerVerifyCode"
              type="number"
              maxlength="6"
              oninput="if(value.length>6)value=value.slice(0,6)"
              placeholder="请输入验证码"
            ></el-input>
            <img
              style="left: 12px"
              src="@/assets/user/login-safe.png"
              class="login-img"
              alt=""
              srcset=""
            />
            <!-- <div class="register-vcerify-code" @click="refreshCode">
              <SecurityCode :identifyCode="identifyCode"></SecurityCode>
            </div> -->
            <el-button plain class="send-vcerify-code" @click="sendVcerifyCode2"
              >{{ time3 == -1 ? "发送验证码" : time3 + "s后重新获取" }}
            </el-button>
          </div>
          <div class="input-wrap">
            <el-input
              v-model="registerPassword"
              type="password"
              maxlength="16"
              show-password
              placeholder="请输入密码"
            ></el-input>
            <img
              src="@/assets/user/login-pass.png"
              class="login-img"
              alt=""
              srcset=""
            />
          </div>
          <div class="input-wrap select">
            <label for="">所属村庄</label>
            <el-select v-model="value" placeholder="请选择村庄">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </template>

        <div
          v-if="activeName != 'forget'"
          :style="
            activeName == 'isVerifyCodeLogin' && pageStatus == 'login'
              ? 'height: 82px'
              : ''
          "
        >
          <el-checkbox
            v-model="rememberPassword"
            v-if="'isPasswordLogin' == activeName && pageStatus != 'register'"
            class="remember-password"
            >记住密码</el-checkbox
          >
          <div
            class="agreement list-check"
            :class="
              activeName == 'isVerifyCodeLogin' || pageStatus != 'login'
                ? 'mb'
                : ''
            "
          >
            <el-checkbox v-model="agreement" label=""
              >{{ pageStatus == "login" ? `登录` : `注册` }} 即默认同意<span
                @click.stop="toArticle(1)"
                >《用户协议》</span
              >、<span @click.stop="toArticle(2)"
                >《隐私政策》</span
              ></el-checkbox
            >
          </div>
        </div>
        <el-button
          type="primary"
          v-if="pageStatus == 'login' && activeName != 'forget'"
          :loading="loginLoading"
          class="login-btn"
          @click.stop="loginSubmit"
          >登录</el-button
        >
        <el-button
          type="primary"
          v-if="pageStatus == 'register' && activeName != 'forget'"
          :loading="registerLoading"
          class="login-btn"
          @click.stop="registerSubmit"
          >注册</el-button
        ><el-button
          type="primary"
          v-if="activeName == 'forget'"
          :loading="forgetLoading"
          class="login-btn"
          @click.stop="submit"
          >提交</el-button
        >
        <div class="login-bottom">
          <template v-if="pageStatus == 'login' && activeName != 'forget'">
            <el-button type="text" @click="changePageStatus"
              >注册账号</el-button
            >
            <el-button type="text" @click="changeStatusForget"
              >找回密码？</el-button
            >
          </template>
          <template v-if="pageStatus == 'register' || activeName == 'forget'">
            <el-button type="text" @click="changeStatusLogin"
              >已有账号？去登录</el-button
            >
          </template>
        </div>
      </div>
    </div>

    <verify-code
      @successHandler="sendVcerifyCode"
      ref="verifycode"
    ></verify-code>
  </div>
</template>

<script>
import verifyCode from "./verifyCode.vue";
import SecurityCode from "@/components/securityCode.vue";
export default {
  components: {
    verifyCode,
    SecurityCode,
  },
  watch: {
    showLogin(newV, oldV) {
      if (newV) {
        this.inputVerifyCode = "";
        this.refreshCode();
        this.stopMove();
      } else {
        this.Move();
      }
    },
    rememberPassword(newV, oldV) {
      if (newV) {
        this.store.set("rp", true);
      } else {
        this.store.remove("rp");
        this.store.remove("p");
      }
    },
  },
  data() {
    return {
      showLogin: false,
      pageStatus: "login",
      registerVerifyCode: "", //注册
      registerPassword: "",
      identifyCodeType: "1234567890", //定义验证类型 1.数字 2.字母
      identifyCode: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      activeName: "isPasswordLogin",
      account: "",
      password: "",
      verifyCode1: "", //登录
      inputVerifyCode: "", //数字验证码
      verifyCode2: "", //找回密码

      rememberPassword: false,
      agreement: false,
      btnLogin: false,
      status: 0,
      timer: null,
      timer2: null,
      timer3: null,
      time: this.store.get("xctime") || -1,
      time2: -1, //找回密码
      time3: -1, //注册
      loginLoading: false,
      registerLoading: false,
      forgetLoading: false,
      forgetPassword: "",
      forgetRePassword: "",
      pw: "",
    };
  },
  created() {
    this.startTime(this.time, "load");
    this.refreshCode();
    // 获取村庄
    this.$getHttp("/pc/smart/getVillages").then((res) => {
      if (res.code == 200) {
        this.options = res.villages.map((item) => {
          return {
            id: item.id,
            value: item.id,
            label: item.villageName,
          };
        });
      }
    });
    // 回显密码
    this.getpass();
  },
  methods: {
    // 保存密码
    savepass(pw) {
      if (this.store.get("rp")) {
        this.pw = pw;
        this.store.set("p", this.pw);
      }
    },
    getpass() {
      if (this.store.get("p")) {
        this.password = this.store.get("p");
        this.rememberPassword = true;
      }
    },
    // 注册提交
    registerSubmit() {
      if (!this.$test.phone(this.account)) {
        this.myErrorMessage("账号格式不正确");
        return false;
      }
      if (this.registerVerifyCode.length != 6) {
        this.myErrorMessage("验证码长度为6位");
        return false;
      }
      if (
        this.registerPassword.length < 6 ||
        this.registerPassword.length > 16
      ) {
        this.myErrorMessage("密码长度应该在6~16位长度以内");
        return false;
      }
      if (!this.value) {
        this.myErrorMessage("请选择村庄");
        return false;
      }
      //
      if (!this.agreement) {
        this.myErrorMessage("请勾选协议与政策");
        return false;
      }
      if (this.registerLoading) {
        return;
      }
      this.registerLoading = true;
      this.$postHttp("/pc/user/registered", {
        userPhone: this.account,
        code: this.registerVerifyCode,
        userPassword: this.registerPassword,
        userVillage: this.value,
      })
        .then((res) => {
          if (res.code == 200) {
            console.log("成功注册");
            this.mySuccessMessage("注册成功");
            this.changeStatusLogin();
          }
          this.registerLoading = false;
        })
        .catch((err) => {
          this.registerLoading = false;
        });
    },
    // 登录提交
    loginSubmit() {
      if (!this.$test.phone(this.account)) {
        this.myErrorMessage("账号格式不正确");
        return false;
      }
      var param = {};
      if (this.activeName == "isVerifyCodeLogin") {
        param = {
          userPhone: this.account,
          code: this.verifyCode1,
        };

        if (this.verifyCode1.length != 6) {
          this.myErrorMessage("验证码长度为6位");
          return false;
        }
      } else {
        param = {
          userPhone: this.account,
          userPassword: this.password,
        };
        if (this.password.length < 6 || this.password.length > 16) {
          this.myErrorMessage("密码长度应该在6~16位长度以内");
          return false;
        }
        if (this.identifyCode != this.inputVerifyCode) {
          this.refreshCode();
          this.myErrorMessage("验证码错误");
          return false;
        }
      }
      if (!this.agreement) {
        this.myErrorMessage("请勾选协议与政策");
        return false;
      }
      if (this.loginLoading) {
        return;
      }
      this.loginLoading = true;
      this.$postHttp("/pc/user/login", param)
        .then((res) => {
          if (res.code == 200) {
            // 保存密码
            this.savepass(this.password);

            this.LOGIN(res.villageUser);

            this.mySuccessMessage("登录成功");

            this.close();
            console.log("成功登录");
          } else {
            console.log(res.msg);
          }
          this.loginLoading = false;
        })
        .catch((err) => {
          this.loginLoading = false;
        });
    },
    //
    submit() {
      if (!this.$test.phone(this.account)) {
        this.myErrorMessage("账号格式不正确");
        return false;
      }
      if (this.verifyCode2.length != 6) {
        this.myErrorMessage("验证码长度为6位");
        return false;
      }
      if (this.forgetPassword.length < 6 || this.forgetPassword.length > 16) {
        this.myErrorMessage("密码长度应该在6~16位长度以内");
        return false;
      }
      if (this.forgetPassword != this.forgetRePassword) {
        this.myErrorMessage("两次密码不一致");
        return false;
      }

      if (this.forgetLoading) {
        return;
      }
      var param = {
        userPhone: this.account,
        userPassword: this.forgetPassword,
        code: this.verifyCode2,
      };
      this.forgetLoading = true;
      this.$postHttp("/pc/user/retrievePassword", param)
        .then((res) => {
          if (res.code == 200) {
            // 保存密码

            this.mySuccessMessage("更改成功，请返回登录");
            this.changeStatusLogin();
            console.log("更改成功");
          } else {
            console.log(res.msg);
          }
          this.forgetLoading = false;
        })
        .catch((err) => {
          this.forgetLoading = false;
        });
    },
    open(type) {
      this.pageStatus = type;
      this.showLogin = true;
    },
    close() {
      this.showLogin = false;
    },
    // 切换登录
    changePageStatus() {
      if (this.pageStatus == "register") {
        this.pageStatus = "login";
        this.activeName = "isPasswordLogin";
      } else {
        this.pageStatus = "register";
      }
      this.agreement = false;
    },
    // 找回密码
    changeStatusForget() {
      this.activeName = "forget";
      this.agreement = false;
    },
    // 去登录
    changeStatusLogin() {
      this.pageStatus = "login";
      this.activeName = "isPasswordLogin";
      this.agreement = false;
    },
    // 注册
    btnRegister() {},
    // 初始化
    init() {
      this.$refs.verifycode.open();
    },
    // 切换密码登录/手机登录
    handleClick(tab, event) {
      console.log(tab, event, tab.index);
      // this.activeName = tab._props.name;
      if (tab.index == 0) {
        // 密码登录
      } else if (tab.index == 1) {
        // 手机登录
      }
      this.agreement = false;
      console.log(this.activeName);
    },
    // 弹出验证码
    sendVcerifyCode2() {
      if (this.pageStatus == "register") {
        if (this.time3 != -1) {
          return false;
        }
        this.$refs.verifycode.open();
        this.$refs.verifycode.handleClick();
        return false;
      }
      if ("forget" == this.activeName) {
        if (this.time2 != -1) {
          return false;
        }
      } else {
        if (this.time != -1) {
          return false;
        }
      }
      this.$refs.verifycode.open();
      this.$refs.verifycode.handleClick();
    },
    // 发送短信
    sendVcerifyCode() {
      if (this.pageStatus == "register") {
        this.startTime3(60);
        this.apiSendVcerifyCode();
        return false;
      }
      if ("forget" == this.activeName) {
        // 找回密码

        this.startTime2(60);
      } else {
        // 验证码登录

        this.startTime(60);
      }
      this.apiSendVcerifyCode();
    },
    apiSendVcerifyCode() {
      if (!this.$test.phone(this.account)) {
        this.myErrorMessage("账号格式不正确");
        return false;
      }
      this.$getHttp("/pc/user/sendVerificationCode", {
        userPhone: this.account,
      }).then((res) => {
        if (res.code == 200) {
        }
      });
    },
    // 开始计时
    startTime(time) {
      if (!time || time == -1) {
        return false;
      }
      this.time = time;
      this.timer = setInterval(() => {
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.store.remove("xctime");
          this.time--;
          return false;
        }
        this.time--;
        this.store.set("xctime", this.time);
      }, 1000);
    },
    // 没有缓存的计时
    startTime2(time) {
      if (!time || time == -1) {
        return false;
      }
      this.time2 = time;
      this.timer2 = setInterval(() => {
        if (this.time2 <= 0) {
          clearInterval(this.timer2);

          this.time2--;
          return false;
        }
        this.time2--;
      }, 1000);
    },
    // 没有缓存的注册计时
    startTime3(time) {
      if (!time || time == -1) {
        return false;
      }
      this.time3 = time;
      this.timer3 = setInterval(() => {
        if (this.time3 <= 0) {
          clearInterval(this.timer3);

          this.time3--;
          return false;
        }
        this.time3--;
      }, 1000);
    },
    //初始化验证码
    refreshCode() {
      this.identifyCode = ""; //输入框置空
      this.makeCode(this.identifyCodeType, 4); //验证码长度为4
    },
    //随机切换验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodeType[
            this.randomNum(0, this.identifyCodeType.length)
          ];
      }
      console.log(this.identifyCode);
    },
    //验证码
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    //停止页面滚动
    stopMove() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, { passive: false }); //禁止页面滑动
    },
    //开启页面滚动
    Move() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", m, { passive: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 999;
  .zbc {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
  }
  ::v-deep .el-tabs {
    margin-top: 20px;
    .el-tabs__item {
      font-size: 16px !important;
    }
    .el-tabs__nav {
      float: none !important;
      width: 154px;
      margin: 0 auto;
    }
    .el-tabs__nav-wrap::after {
      background-color: #fff !important;
    }
  }

  .login-wrap {
    position: fixed;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 446px;
    // height: 524px;
    background: #ffffff;
    box-shadow: 0px 0px 39px 1px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    padding: 30px 36px;
    .login-close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
    .login-text {
      text-align: center;
      height: 36px;
      line-height: 36px;
      font-size: 36px;

      font-weight: 500;
      color: #000000;
      margin: 10px 0 0 12px;
      &.mb {
        margin-bottom: 22px;
      }
    }
    .input-wrap {
      position: relative;
      height: 58px;
      line-height: 58px;
      width: 100%;
      border-radius: 6px;
      background-color: #f3f3f3;
      ::v-deep .el-input__inner {
        width: 100%;
        outline: none;
        background-color: transparent;
        padding-left: 46px;
        border: 1px solid transparent !important;
      }
      margin-top: 10px;
      &:first-child {
        margin-top: 5px;
      }
      // 下拉
      &.select {
        label {
          width: 92px;
        }
        background-color: transparent;
        display: flex;
        ::v-deep .el-select {
          background-color: #f3f3f3;
          border-radius: 6px;
          width: 100%;
          .el-input {
            width: 100%;
            .el-input__inner {
              padding-left: 16px;
            }
          }
        }
      }
      .login-img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
        width: 23px;
        height: auto;
        &:last-child {
          left: 14px;
          width: 19px;
        }
      }
      // 验证码登录
      &.verify-code-login {
        background-color: transparent;
        display: flex;
        ::v-deep .el-input {
          background-color: #f3f3f3;
          border-radius: 6px;
        }
        .send-vcerify-code {
          margin-left: 4%;
          // width: 32%;
          height: 58px;
          line-height: 58px;
          vertical-align: middle;
          padding: 0 20px;

          font-size: 18px;
          font-weight: 400;
          color: #000000;
        }
        // 验证码注册
        .register-vcerify-code {
          position: relative;
          z-index: 9999;
          display: inline-block;
          vertical-align: middle;
          margin-left: 4%;
          padding: 0;
          padding-top: 10px;
          height: 39px;
          line-height: 39px;
        }
      }
    }
    .remember-password {
      margin-top: 16px;
      ::v-deep .el-checkbox__label {
        line-height: 20px !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #000000 !important;
      }
      ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: transparent !important;
      }
      ::v-deep .el-checkbox__inner {
        border-color: #e3e3e3 !important;
        &::after {
          border: 1px solid #00d738;
          border-left: 0;
          border-top: 0;
        }
      }
    }

    .agreement {
      translate: all 0.3s;
      margin-top: 8px;
      font-size: 16px;
      font-weight: 400;
      ::v-deep .el-radio__input.is-checked + .el-radio__label {
        color: #000000 !important;
      }
      ::v-deep .el-radio__input.is-checked .el-radio__inner {
        border-color: #e3e3e3 !important;
        background: transparent !important;
      }
      ::v-deep .el-radio__inner::after {
        background-color: #000000 !important;
      }
      &.mb {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        padding-top: 20px;
        // margin-bottom: 13px;
      }
    }
    .login-btn {
      width: 100%;
      border-radius: 50px;
      margin-top: 24px;

      height: 67px;
      // line-height: 67px;
      font-size: 22px;
      text-align: center;
      font-weight: 400;
      color: #ffffff;
      background-color: var(--theme-color);
    }
    .login-bottom {
      margin-top: 15px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      height: 16px;
      line-height: 16px;
      font-size: 16px;

      font-weight: 400;
      color: #000000;
      ::v-deep .el-button--text {
        color: #000000;
      }
    }
  }
}
</style>
