import router from "../../router/index.js";

export default {
  // namespaced: true,
  state: {
    // 用户信息
    userInfo: localStorage.getItem("xcuserInfo")
      ? JSON.parse(localStorage.getItem("xcuserInfo"))
      : null,
    bg: localStorage.getItem("xcbg")
      ? localStorage.getItem("xcbg")
      : require("@/assets/temp/main-bg.png"),
    bg2: localStorage.getItem("xcbg2")
      ? localStorage.getItem("xcbg2")
      : require("@/assets/image/cuijiaji2.png"),
    msgCount: localStorage.getItem("xcmsgCount")
      ? localStorage.getItem("xcmsgCount")
      : 0,
    showComplaint: false,
  },
  getters: {},
  mutations: {
    //登录
    LOGIN(state, userInfo) {
      console.log("登录");
      if (typeof userInfo == "object") {
        console.log("tureturetureture", userInfo);
        if (userInfo.userState == 2) {
          // state.showComplaint = true;
          // localStorage.setItem("xctempId", userInfo.id);
          // return false;
        } else {
          localStorage.setItem("xcuserInfo", JSON.stringify(userInfo));
          state.userInfo = userInfo;
        }
      }
    },
    // 退出登录
    LOGOUT(state) {
      console.log("登出");
      state.userInfo = "";
      state.msgCount = 0;
      localStorage.removeItem("token");
      localStorage.removeItem("xcmsgCount");
      localStorage.removeItem("xcuserInfo");
      router.replace("/home");
    },
    SETBG(state, bg) {
      localStorage.setItem("xcbg", bg);
      state.bg = bg;
    },
    SETBG2(state, bg) {
      localStorage.setItem("xcbg2", bg);
      state.bg2 = bg;
    },
    SETMSG(state, msg) {
      localStorage.setItem("xcmsgCount", msg);
      state.msgCount = msg;
    },
    setShowComplaint(state, showComplaint) {
      state.showComplaint = showComplaint;
    },
  },
  actions: {},
};
