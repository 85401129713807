<template lang="html">
  <div class="editor" style="width: 100%; z-index: 999">
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" class="text"></div>
  </div>
</template>

<script>
import E from "wangeditor";
export default {
  name: "WangEditor",
  data() {
    return {
      editor: null,
      info_: null,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    isClear: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isClear(val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear();
        this.info_ = null;
      }
    },
    value: {
      handler(newV, oldV) {
        console.log("111111", newV);
        if (newV) {
          console.log("111111");
          // this.init(newV);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.seteditor();
  },
  methods: {
    getCharLength(val) {},
    init(val) {
      // 使用 v-model 时，设置初始值
      console.log(1111111111222222222222222222);
      this.editor.txt.html(val);
    },
    setDisabled(val = true) {
      if (val) {
        this.editor.disable();
      } else {
        this.editor.enable();
      }
    },
    seteditor() {
      this.editor = new E(this.$refs.toolbar, this.$refs.editor);
      this.editor.config.uploadImgShowBase64 = false; // base 64 存储图片
      // this.editor.config.uploadImgServer = '/api/ht/attach/qnUpload' // 配置服务器端地址
      // 取消聚焦
      // this.editor.config.focus = false
      // this.editor.create()

      // // 取消自动focus且禁止虚拟键盘弹出
      // document.activeElement.blur();
      // this.editor.config.disabled = this.disabled;

      // this.editor.config.uploadImgServer =
      //   "https://www.bangbangtongcheng.com/conven/ht/attach/qnUpload"; // 配置服务器端地址
      // this.editor.config.uploadImgServer = this.$upLoad;
      this.editor.config.uploadImgServer =
        "https://www.cuijiajishuzixiangcun.cn/digitalvillage/ht/attach/upload";
      this.editor.config.uploadImgHeaders = {}; // 自定义 header
      this.editor.config.uploadFileName = "file"; // 后端接受上传文件的参数名
      this.editor.config.uploadImgMaxSize = 20 * 1024 * 1024; // 将图片大小限制为 2M
      this.editor.config.uploadImgMaxLength = 10; // 限制一次最多上传 3 张图片
      this.editor.config.uploadImgTimeout = 3 * 60 * 1000; // 设置超时时间
      // this.editor.config.uploadVideoServer = '/api/conven/ht/attach/qnUpload'
      // this.editor.config.uploadVideoServer =
      //   "https://www.bangbangtongcheng.com/conven/ht/attach/qnUpload";
      // this.editor.config.uploadVideoServer = this.$upLoad;
      this.editor.config.uploadVideoServer =
        "https://www.cuijiajishuzixiangcun.cn/digitalvillage/ht/attach/upload";
      this.editor.config.uploadVideoName = "file";
      this.editor.config.lineHeights = [
        "1",
        "1.2",
        "1.4",
        "1.6",
        "1.8",
        "2",
        "2.5",
        "3",
      ];
      this.editor.config.colors = [
        "#000000",
        "#ffffff",
        "#A9A9A9",
        "#808080",
        "#696969",
        "#ff0000",
        "#ff7f00",
        "#ffff00",
        "#00ff00",
        "#00ffff",
        "#0000ff",
        "#8b00ff",
      ]; // 配置颜色（文字颜色、背景色）
      // 配置菜单
      this.editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "lineHeight", //行高
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "video", // 插入视频
        "code", // 插入代码
        "undo", // 撤销
        "redo", // 重复
      ];
      this.editor.config.uploadImgHooks = {
        fail: (xhr, editor, result) => {
          // 插入图片失败回调
          console.log(xhr, editor, result);
        },
        success: (xhr, editor, result) => {
          // 图片上传成功回调
          console.log(xhr, editor, result);
        },
        timeout: (xhr, editor) => {
          // 网络超时的回调
          console.log(xhr, editor);
        },
        error: (xhr, editor) => {
          // 图片上传错误的回调
          console.log(xhr, editor);
        },
        customInsert: (insertImg, result, editor) => {
          // 图片上传成功，插入图片的回调
          console.log(result, "成功"); // 图片上传成功，插入图片的回调
          result.data.forEach((e) => {
            insertImg(this.imgUrl + e.url);
          });
        },
      };
      this.editor.config.uploadVideoHooks = {
        fail: (xhr, editor, result) => {
          // 插入图片失败回调
        },
        success: (xhr, editor, result) => {
          // 图片上传成功回调
        },
        timeout: (xhr, editor) => {
          // 网络超时的回调
        },
        error: (xhr, editor) => {
          // 图片上传错误的回调
        },
        customInsert: (insertImg, result, editor) => {
          // 图片上传成功，插入图片的回调
          console.log(result, "成功"); // 图片上传成功，插入图片的回调
          insertImg(this.imgUrl + result.data[0].url);
        },
      };
      this.editor.config.onchange = (html) => {
        this.info_ = html; // 绑定当前逐渐地值
        console.log("111");
        // if (this.editor.txt.text().length > 5) {
        //   // 限制富文本输入内容
        //   this.info_ = this.editor.txt.text().substring(0, 5000).length;
        //   this.editor.txt.html(this.editor.txt.text().substring(0, 5000));
        // } else {
        //   this.info_ = this.editor.txt.text().length;
        //   this.editor.txt.html();
        // }
        this.$emit("change", this.info_); // 将内容同步到父组件中
      };
      // 创建富文本编辑器
      this.editor.create();
    },
  },
};
</script>

<style lang="css">
.editor {
  width: 100%;
  margin: 0 auto;
}

.toolbar {
  border: 1px solid #ccc;
}

.text {
  border: 1px solid #ccc;
  height: 360px;
}
/* .w-e-menu {
  z-index: 93 !important;
}
.w-e-text-container {
  z-index: 90 !important;
}
.w-e-toolbar {
  z-index: 92 !important;
} */
.w-e-toolbar {
  z-index: 101 !important;
}
.w-e-text-container {
  z-index: 90 !important;
}
</style>
