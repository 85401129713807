import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import storeJs from "storejs";
Vue.prototype.store = storeJs;
import api from "./api/request";
Vue.use(api);
Vue.prototype.$store = store;
import ElementUI from "element-ui";
import { Message } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
Vue.prototype.$message = Message;

import "normalize.css/normalize.css";

import "bootstrap";
import "babel-polyfill";
// 引入bootstrap样式
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import mix from "./utils/mix";
Vue.mixin(mix);
import $ from "jquery";
Vue.prototype.$ = $;

import WangEditor from "@/components/WangEditor";
Vue.component("WangEditor", WangEditor);

import axios from "axios";
Vue.prototype.$axios = axios;

import util from "./utils/util";
Vue.prototype.$util = util;

import VueCropper from "vue-cropper";
Vue.use(VueCropper);
import hevueImgPreview from "hevue-img-preview";
Vue.use(hevueImgPreview);
import SlideVerify from "vue-monoplasty-slide-verify";
Vue.use(SlideVerify);

Vue.prototype.$imgSuffixArr = [".jpg", ".png", ".jpeg", ".gif"].join(",");
Vue.prototype.$videoSuffixArr = [
  ".mp4",
  ".flv",
  ".avi",
  ".mpg",
  ".wmv",
  ".3gp",
  ".mov",
  ".asf",
  ".asx",
  ".vob",
  ".wmv9",
  ".rm",
  ".rmvb",
];
Vue.prototype.$imgAvideo = [
  ".jpg",
  ".png",
  ".jpeg",
  ".gif",
  ".mp4",
  ".flv",
  ".avi",
  ".mpg",
  ".wmv",
  ".3gp",
  ".mov",
  ".asf",
  ".asx",
  ".vob",
  ".wmv9",
  ".rm",
  ".rmvb",
];
import test from "@/utils/test";
Vue.prototype.$test = test;
Vue.prototype.$store = store;
Vue.config.productionTip = false;

import dataV from "@jiaminghi/data-view";

Vue.use(dataV);

if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0; //truncate if number or convert non-number to 0;
    padString = String(typeof padString !== "undefined" ? padString : " ");
    if (this.length > targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(this);
    }
  };
}

import "./utils/drag";
import "./utils/drag1";
import "./utils/drag2";
import "@/assets/css/reset.css";
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
