<template>
  <div class="main-home">
    <div class="advertisement">
      <img
        class="advertisement-img"
        :src="bg2"
        @click="cuijiaji"
        data-aos="zoom-in-left"
      />
    </div>

    <div class="main-bg-wrap">
      <head-top showNews style="z-index: 99"></head-top>
      <div
        class="main-bg-img-new"
        :style="'background-image:url(&quot;' + bg + '&quot;)'"
      ></div>

      <div class="wraper-title">
        <div class="title-list-wrap">
          <div
            class="title"
            v-for="item in navTitle"
            :class="navIndex == item.value ? 'active' : ''"
            :key="item.id"
            v-if="!item.hidden"
            @click="selectTitle(item)"
          >
            {{ item.label }}
          </div>
        </div>
      </div>

      <div class="search-wrap-new" data-aos="fade-down">
        <el-input
          v-model.trim="searchKeyword"
          placeholder="请输入关键字"
          class="search"
          resize="both"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
          <div slot="append" class="search-append" @click="search()">搜索</div>
        </el-input>
      </div>

      <div class="wraper3">
        <div class="wraper-left">
          <div class="carousel-wrap" data-aos="fade-right">
            <el-carousel
              indicator-position="none"
              height="280px"
              style="width: 638px; height: 100%"
              arrow="always"
            >
              <el-carousel-item v-for="item in carouselList2" :key="item.id">
                <img
                  @click="goArticle(item)"
                  :src="imgUrl + item.rotationPictrue"
                  class="carousel-img"
                  alt=""
                  srcset=""
                />
              <div class="carousel-title line1" v-if="item.rotationTitle">{{item.rotationTitle}}</div>

              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="category-wraper" data-aos="fade-up">
            <div
              class="category-item"
              v-for="(item, index) in categoryList"
              :style="item.style"
              :key="item.id"
              @click="goCategory(item, index)"
            >
              <img
                :src="item.image"
                @dragstart.prevent
                alt=""
                class="category-img"
                srcset=""
              />
              <div class="category-text">{{ item.text }}</div>
            </div>
          </div>
        </div>

        <div class="wraper-right" data-aos="fade-left">
          <div class="news-title" @click="goNewPath('/notice?type=1')">
            <span class="main-title">公告通知</span>
          </div>
          <div class="public-list">
            <div
              class="public-item"
              v-for="item in publicList"
              @click="goArticle(item)"
              :key="item.id"
            >
              <div class="public-title">{{ item.infoTitle }}</div>
              <div class="public-time">{{ item.infoDate }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="image-wraper wraper3">
        <img
          class="image-full"
          :data-aos="
            index == 0
              ? 'zoom-in-up'
              : index == 2
              ? 'zoom-in-right'
              : index == 3
              ? 'zoom-in-left'
              : 'zoom-in-up'
          "
          v-for="(item, index) in pictureShowList"
          :key="item.key"
          :src="imgUrl + item.showPicture"
          :class="item.id > 1 && item.id < 4 ? 'image-half' : 'image-full'"
          :style="item.id == 2 ? 'margin-right:20px;' : ''"
          @click="goArticle(item)"
        />
      </div>
    </div>
    <div class="feature-product wraper3">
      <img
        class="feature-product-log"
        data-aos="zoom-in-up"
        :src="imgUrl + showImg1"
        @dragstart.prevent
      />
      <div class="log-label" style="font-size: 80px">特色产品</div>
      <div class="feature-product-list">
        <div class="feature-product-left">
          <div
            class="feature-product-left-item"
            :key="index"
            :data-aos="index % 2 == 0 ? 'zoom-in-right' : 'zoom-in-up'"
            @click="goArticle({ ...item, isFeature: item.id })"
            v-for="(item, index) in featuredList"
          >
            <img
              alt=""
              class="feature-product-left-img"
              :src="imgUrl + item.picture"
            />
            <div class="feature-product-left-content line2">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="feature-product-right" data-aos="zoom-in-left">
          <div
            class="feature-product-right-title"
            @click="goNewPath('/notice?type=222')"
          >
            最新动态
          </div>
          <div
            class="feature-product-right-content line1"
            :key="index"
            @click="goArticle(item)"
            v-for="(item, index) in latestNewsList"
          >
            {{ item.infoTitle }}
          </div>
        </div>
      </div>
    </div>
    <div class="tomato-product wraper3">
      <div class="tomato-product-left">
        <img
          :src="imgUrl + showImg2"
          data-aos="zoom-in-up"
          alt=""
          class="tomato-product-left-log"
          @dragstart.prevent
        />
        <div class="log-label" style="line-height: 100px">图说小西红柿</div>

        <div class="tomato-product-left-list">
          <div class="swiper-wrapper">
            <div
              class="tomato-product-left-item swiper-slide"
              data-aos="zoom-in-up"
              :data-aos-delay="(tomatoList.length - 1 - index) * 100"
              v-for="(item, index) in tomatoList"
              @click="goArticle(item)"
              :key="index"
            >
              <img
                class="tomato-product-left-img"
                :src="imgUrl + item.picture"
              />
              <div class="tomato-product-left-text line1">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tomato-product-right" data-aos="zoom-in-left">
        <div
          class="news-title noicon"
          @click="
            goNewPath(
              '/agriculturalDemandAndSupply/agriculturalProducts?cate=1'
            )
          "
        >
          <span class="main-title">农产供需</span>
        </div>
        <div class="tomato-product-right-title"></div>
        <div class="tomato-product-right-list">
          <div class="swiper-wrapper">
            <div
              class="tomato-product-right-item swiper-slide"
              v-for="item in publicnList"
              :key="item.id"
              @click="goArticle(item)"
            >
              <div class="tomato-product-right-title line1">
                {{ item.title }}
              </div>
              <div class="tomato-product-right-time">{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="second-content wraper3">
      <div class="wraper-left" data-aos="fade-right">
        <div class="content-title" @click="goNewPath('/notice?type=2')">
          信息公开
        </div>
        <div class="content-list">
          <div
            @click="goArticle(item)"
            class="content-item"
            v-for="(item, index) in informationList"
            :class="index % 2 == 0 ? 'pr' : 'pl'"
            :key="item.id"
          >
            <div class="title line1">{{ item.infoTitle }}</div>
            <div class="time">{{ item.infoDate }}</div>
          </div>
        </div>
      </div>

      <div class="wraper-right" data-aos="fade-left">
        <div class="content-title" @click="goNewPath('/notice?type=3')">
          党建风采
        </div>
        <div class="content-list">
          <div
            @click="goArticle(item)"
            class="content-item"
            v-for="item in partyList"
            :key="item.id"
          >
            <img :src="$imgUrl + item.elegantPicture" alt="" class="thumb" />
            <div class="title">{{ item.elegantTitle }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="study-content wraper3">
      <div class="module-title" data-aos="fade-right">
        <span class="module-title-text">学习平台</span>
      </div>
      <div class="imgs-wraper">
        <img
          data-aos="flip-left"
          :data-aos-delay="index * 100"
          v-for="(item, index) in terraceList"
          :key="item.id"
          :src="imgUrl + item.terracePicture"
          alt=""
          class="wraper-img"
        />
      </div>
      <div class="study-container">
        <div class="study-wraper" data-aos="fade-up" data-aos-delay="100">
          <div
            class="wraper-title"
            @click="goNewPath('/governmentServices/policyPublicity?cate=3')"
          >
            政策宣传
          </div>
          <div class="wraper-list">
            <div
              @click="goArticle(item)"
              class="list-item"
              v-for="item in policyPropagandaList"
              :key="item.id"
            >
              <div class="title line1">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="study-wraper" data-aos="fade-up" data-aos-delay="200">
          <div class="wraper-title" @click="goNewPath('/notice?type=4')">
            乡村振兴
          </div>
          <div class="wraper-list">
            <div
              @click="goArticle(item)"
              class="list-item"
              v-for="item in vigorouslyList"
              :key="item.id"
            >
              <div class="title line1">{{ item.vigorouslyTitle }}</div>
            </div>
          </div>
        </div>
        <div
          class="study-wraper"
          data-aos="fade-up"
          data-aos-delay="300"
          style="margin-right: 0"
        >
          <div class="wraper-title" @click="goNewPath('/notice?type=5')">
            法律讲堂
          </div>
          <div class="wraper-list">
            <div
              @click="goArticle(item)"
              class="list-item"
              v-for="item in lawList"
              :key="item.id"
            >
              <div class="title line1">{{ item.lawTitle }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="science-content wraper3">
      <div class="module-title" data-aos="fade-right">
        <span class="module-title-text">科学种植</span>
      </div>
      <div class="science-container">
        <div class="imgs-wraper">
          <div
            data-aos="fade-up-right"
            :data-aos-delay="index * 100"
            class="wraper-img"
            v-for="(item, index) in scienceleftList"
            :key="item.id"
            @click="goArticle(item)"
            :style="
              'background-image:url(&quot;' +
              imgUrl +
              item.sciencePicture +
              '&quot;)'
            "
          >
            <div class="img-text">{{ item.typeName }}</div>
          </div>
        </div>
        <div class="science-list">
          <div
            data-aos="fade-up"
            :data-aos-delay="index * 100"
            @click="goArticle(item)"
            class="list-item"
            v-for="(item, index) in scienceList"
            :key="item.id"
          >
            <div class="title line1">{{ item.scienceTitle }}</div>
          </div>
        </div>
        <div class="science-switch" data-aos="fade-up-left">
          <img
            src="@/assets/image/science-top.png"
            alt=""
            class="switch-btn"
            @click="setScienceTypesIndex(-1)"
          />
          <div class="science-bg">
            <div class="science-bg-wrap" :style="'transform:' + transformY">
              <div
                @click.stop="gogogo(item)"
                @mouseenter="switchCurrentScienceType(item.id)"
                :class="
                  'switch-item' +
                  (item.id == currentScienceType ? ' active' : '')
                "
                v-for="item in scienceTypes"
                :key="item.id"
              >
                <span>{{ item.typeName }}</span>
              </div>
            </div>
          </div>
          <img
            src="@/assets/image/science-bottom.png"
            alt=""
            class="switch-btn"
            @click="setScienceTypesIndex(1)"
          />
        </div>
      </div>
    </div>

    <div class="pictorial-content wraper3">
      <div class="module-title" data-aos="fade-right">
        <span class="module-title-text">画报里的崔家集</span>
      </div>
      <!-- <div class="pictorial-container">
        <div class="pictorial-wrapper2">
          <div
            data-aos="flip-left"
            :data-aos-delay="index * 100"
            v-for="(item, index) in pictorialList"
            :key="item.id"
            class="pictorial-wraper"
          >
            <img
              :src="imgUrl + item.pictorialPicture"
              alt=""
              class="pictorial-img"
            />
            <div class="pictorial-title">{{ item.pictorialTitle }}</div>
          </div>
        </div>
      </div> -->
      <div class="bottom-content wraper3" style="margin-bottom: 0">
        <div class="swiper-container-new2" style="height: 246px">
          <div class="swiper-wrapper">
            <div
              data-aos="flip-right"
              :data-aos-delay="index * 100"
              class="swiper-slide"
              v-for="(item, index) in pictorialList"
              :key="'carouse' + item.id"
            >
              <img
                :src="imgUrl + item.pictorialPicture"
                style="width: 100%; height: 202px"
                alt=""
                class="an-img"
                srcset=""
              />
              <div
                style="text-align: center; margin-top: 10px; font-size: 18px"
              >
                {{ item.pictorialTitle }}
              </div>
            </div>

            <div class="swiper-pagination2"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="second-content wraper3">
      <div class="wraper-left1" data-aos="fade-right">
        <div class="content-title" @click="goNewPath('/notice?type=333')">
          民生快报
        </div>
        <div class="content-list">
          <div
            @click="goArticle(item)"
            class="content-item"
            v-for="(item, index) in minshengExpressList"
            :class="index % 2 == 0 ? 'pr' : 'pl'"
            :key="item.id"
          >
            <div class="title line1">{{ item.infoTitle }}</div>
            <div class="time">{{ item.infoDate }}</div>
          </div>
        </div>
      </div>

      <div class="wraper-right1" data-aos="fade-left">
        <div class="content-title" @click="goNewPath('/notice?type=444')">
          崔家集要闻
        </div>
        <div class="content-list">
          <div
            @click="goArticle(item)"
            class="content-item"
            v-for="(item, index) in importantNewsList"
            :class="index % 2 == 0 ? 'pr' : 'pl'"
            :key="item.id"
          >
            <div class="title line1">{{ item.infoTitle }}</div>
            <div class="time">{{ item.infoDate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="life-content wraper3">
      <div class="module-title" data-aos="fade-right">
        <span class="module-title-text">健康生活</span>
      </div>
      <div class="life-container">
        <div class="life-tabs" data-aos="fade-up-right">
          <div
            v-for="item in lifeTypes"
            :key="item.id"
            @click="switchLifeType(item.id)"
            :class="'life-tab' + (item.id == lifeIndex ? ' active' : '')"
          >
            {{ item.typeName }}
          </div>
        </div>
        <div class="life-titles" data-aos="fade-up">
          <div
            @click="goArticle(item)"
            class="list-item"
            v-for="item in lifeList"
            :key="item.id"
          >
            <div class="title line1">{{ item.title }}</div>
          </div>
        </div>
        <img
          class="life-thumb"
          data-aos="fade-up-left"
          :src="imgUrl + lifePicture"
          alt=""
        />
      </div>
      <div class="imgs-wraper">
        <div
          data-aos="flip-left"
          :data-aos-delay="index * 100"
          v-for="(item, index) in lifeNewList"
          :key="item.key"
          @click="goArticle(item)"
          class="img-item"
        >
          <img :src="imgUrl + item.lifeNewPicture" alt="" class="img an-img" />
          <div class="title">{{ item.lifeNewTitle }}</div>
        </div>
      </div>
    </div>

    <div class="bottom-wrap wraper3">
      <div class="module-title" data-aos="fade-left">
        <span class="module-title-text">我要办事</span>
      </div>
      <div class="bottom-content wraper3">
        <div class="swiper-container-new">
          <div class="swiper-wrapper">
            <div
              :data-idx="item.id"
              data-aos="flip-right"
              :data-aos-delay="index * 100"
              class="swiper-slide"
              v-for="(item, index) in carouselList"
              :key="'carouse' + item.id"
              :style="'background-image:url(' + item.infoPicture + ')'"
            >
              <img
                :data-idx="item.id"
                @click="gogo(item)"
                :src="item.infoPicture"
                style="width: 100%; height: 100%"
                alt=""
                class="an-img"
                srcset=""
              />
            </div>

            <div class="swiper-pagination2"></div>
          </div>
        </div>
      </div>
    </div>
    <xcfooter></xcfooter>
  </div>
</template>

<script>
import xcfooter from "@/components/footer.vue";
import headTop from "@/components/headTop";
import Swiper from "swiper";
// import "swiper/css/swiper.css";
import axios from "axios";
import "aos/dist/aos.css";
import AOS from "aos/dist/aos.js";
var that = {};
export default {
  components: {
    xcfooter,
    headTop,
  },
  computed: {
    // transformY() {
    //   if (this.scienceTypes.length <= 3) {
    //     return 0;
    //   } else {
    //     var yi = Math.min(
    //       Math.max(0, this.scienceTypesIndex),
    //       this.scienceTypes.length - 3
    //     );
    //     // console.log("yi", yi, `transformY(-${yi * 105}px)`);
    //     return `transformY(-${yi * 105}px)`;
    //   }
    // },
  },
  watch: {
    userInfo: {
      handler(newV, oldV) {
        if (newV) {
          // 如果可访问
          this.navTitle.map((item) => {
            if ("数字大屏" == item.label)
              item.hidden = !(newV.userRole == 3 || newV.userRole == 4);
          });
        } else {
          this.navTitle.map((item) => {
            if ("数字大屏" == item.label) item.hidden = true;
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    gogogo(item) {
      this.goNewPath(`/notice?id=${item.id}&type=6&typeName=${item.typeName}`);
      // console.log("qq", id);
    },
    switchLifeType(id) {
      this.lifeIndex = id;
      this.getLifePicture();
      this.getLife();
    },
    setScienceTypesIndex(type) {
      //调整科学页面tabs用，暂时未定
      // if (type == 0 && this.scienceTypesIndex > 0) {
      //   this.scienceTypesIndex--;
      // } else if (type == 1 && this.scienceTypesIndex < 1) {
      //   this.scienceTypesIndex++;
      // }
      // this.getScience();

      if (this.scienceTypes.length <= 3) {
        return false;
      }
      this.scienceTypesIndex = Math.min(
        Math.max(0, this.scienceTypesIndex + Number(type)),
        this.scienceTypes.length - 3
      );

      this.transformY = `translateY(-${this.scienceTypesIndex * 105}px)`;

      var tempI = this.scienceTypes.findIndex(
        (item) => item.id == this.currentScienceType
      );

      // if (
      //   tempI < this.scienceTypesIndex ||
      //   tempI >= this.scienceTypesIndex + 3
      // ) {
      //   this.switchCurrentScienceType(
      //     this.scienceTypes[tempI + Number(type)].id
      //   );
      // }
      console.log(
        "this.scienceTypes[tempI + Number(type)",
        this.scienceTypes[tempI + Number(type)]
      );
      this.switchCurrentScienceType(this.scienceTypes[tempI + Number(type)].id);
    },
    switchCurrentScienceType(id) {
      if (id == this.currentScienceType) return false;
      this.currentScienceType = id;
      this.getScience();
    },
    gogo(item) {
      this.openUrl(item.infoTitle);
    },
    search() {
      if (!this.searchKeyword) return;
      this.goNewPath("/search", {
        searchKeyword: this.searchKeyword,
      });
      // this.$router.push({
      //   path: "",
      //   query: {

      //   },
      // });
    },
    goCategory(item, index) {
      switch (index) {
        case 0:
          //政策服务
          this.goNewPath("/governmentServices/policyPublicity?cate=3");
          break;
        case 1:
          //调查问卷
          this.goNewPath("/governmentServices/questionnaire");
          break;
        case 2:
          //生活服务
          // this.goNewPath("/convenientLife/convenient?cate=1");
          this.openUrl(
            "http://qdzwfw.sd.gov.cn/qd/icity/mobile/ali/businessguide/menu?region_code=370283000000&orgin=PDSXZSPJ&istysp=1&enter=zwt&issb=0"
          );

          break;
        case 3:
          // 智慧医疗
          this.goNewPath("/convenientLife/convenient?cate=3");
          break;
        case 4:
          // 农产供需
          this.goNewPath(
            "/agriculturalDemandAndSupply/agriculturalProducts?cate=1"
          );
          break;
        case 5:
          // 农资供需
          this.goNewPath(
            "agriculturalDemandAndSupply/agriculturalProducts?cate=3"
          );

          break;
        default:
          break;
      }
    },
    goPolicyPublicity(item) {},
    selectTitle(item) {
      if (item.value == 9) {
        if (!this.store.get("xcuserInfo")) {
          this.myErrorMessage("请先登录");
          return;
        }
        if (this.userInfo.userRole == 3 || this.userInfo.userRole == 4) {
          let route2 = this.$router.resolve({
            name: "show", //这里是跳转页面的name
            query: {
              // id:id   //要传的参数
            },
          });
          window.open(route2.href, "_blank");
        } else {
          this.myErrorMessage("您的权限不足以访问！");
        }

        return;
      }
      this.$router.push({
        path: item.path,
      });
    },
    cuijiaji() {
      // this.getNewPath("/cuijiaji");
      this.goNewPath({
        path: "/cuijiaji",
      });
    },
    getCarousel() {
      this.$getHttp("/pc/rotation/getRotation", { rotationTypeFirst: 1 }).then(
        (data) => {
          if (data.code == 200) {
            var arr = data.data;
            this.imgSplit(arr);

            this.carouselList3 = arr.filter((item) => {
              return item.rotationTypeSecond == 1;
            })[0].imgArr;
            this.carouselList2 = arr.filter((item) => {
              return item.rotationTypeSecond == 2;
            });

            this.carouselList4 = arr.filter((item) => {
              return item.rotationTypeSecond == 3;
            });

            // this.showImg1 = (
            //   arr.filter((item) => {
            //     return item.rotationTypeSecond == 4;
            //   })[0] || {}
            // ).rotationPictrue;
            // this.showImg2 = (
            //   arr.filter((item) => {
            //     return item.rotationTypeSecond == 5;
            //   })[0] || {}
            // ).rotationPictrue;

            if (
              this.carouselList4[0] &&
              this.carouselList4[0].rotationPictrue
            ) {
              this.SETBG2(this.$imgUrl + this.carouselList4[0].rotationPictrue);
              //拉取侧边图像
            }
            if (this.carouselList3[0]) {
              this.SETBG(this.carouselList3[0]);
            }

            // this.SETBG2(
            //   this.carouselList4[0]
            //     ? this.carouselList4[0].rotationPictrue
            //     : require("@/assets/image/cuijiaji.png")
            // );
            // this.SETBG(this.carouselList3[0]);
            // console.log(this.carouselList3);
            // console.log(this.carouselList2);
          }
        }
      );
    },
    // 获取主要信息
    getInfo() {
      this.$getHttp("/pc/info/get").then((data) => {
        if (data.code == 200) {
          // console.log("info",data);
          this.imgAddPrefix(data.data);

          this.publicList = data.data.announcementToInform.slice(0, 5);
          this.informationList = data.data.informationDisclosure.slice(0, 10);
          this.latestNewsList = data.data.latestDevelopmentOf.slice(0, 5); // data.data.iWantToDo.map((item) => {
          this.minshengExpressList = data.data.minshengExpress.slice(0, 5);
          this.importantNewsList = data.data.importantNews.slice(0, 5);
          //   item.infoPicture =
          //     "https://pics3.baidu.com/feed/f9198618367adab45ef61786e0dce5158601e4e6.jpeg";
          // });
          // data.data.iWantToDo.map((item) => {
          //   item.infoPicture =
          //     "http://shuzixiangcun.lefenglian.top/digitalvillage/upload//userfiles/1/程序附件//village/htVillageInfo/2023/2/测试用图片(4).png";
          // });

          this.carouselList = data.data.iWantToDo;

          setTimeout(() => {
            // https://blog.csdn.net/qq_41046662/article/details/88035800
            var mySwiper2 = new Swiper(".swiper-container-new", {
              direction: "horizontal", // 水平切换选项    垂直vertical  默认是水平切换模式
              loop: true, // 循环模式选项
              autoplay: {
                delay: 0,

                disableOnInteraction: false,
              }, //可选选项，自动滑动
              speed: 3000,
              // pagination: {
              //   el: ".swiper-pagination2", //分页器 -
              // },
              slidesPerView: "auto", //设置slider容器能够同时显示的slides数量
              noSwiping: false, //设为true时，可以在slide上（或其他元素）增加类名'swiper-no-swiping'，使该slide无法拖动，希望文字被选中时可以考虑使用。该类名可通过noSwipingClass修改
              watchSlidesProgress: true, //开启这个参数来计算每个slide的progress(进度、进程)
              paginationClickable: false,
              slidesPerView: "auto", //设置slider容器能够同时显示的slides数量
              spaceBetween: 9, //在slide之间设置距离 默认单位px 也可以设置% vw vh ....
              centeredSlides: false, //设定为true时，active slide会居中，而不是默认状态下的居左
              // slidesOffsetBefore: 60, //设定slide与左边框的预设偏移量 还有slidesOffsetAfter 反之
              on: {
                click: function (e) {
                  const el = e.target; // 获取当前点击的元素
                  let idx = ((el || {}).dataset || {}).idx;
                  let obj =
                    that.carouselList.filter((item) => item.id == idx)[0] || {};
                  console.log("e", e);
                  that.gogo(obj);
                },
              },
            });
          }, 200);
        }
      });
    },

    getElegant() {
      this.$getHttp("/pc/info3/getElegant").then((data) => {
        if (data.code == 200) {
          // console.log("风采",data);
          this.partyList = data.page.list.slice(0, 3);
        }
      });
    },
    getPictureShow() {
      this.$getHttp("/pc/info3/getPictureShow").then((data) => {
        if (data.code == 200) {
          console.log("图片", data);
          this.pictureShowList = data.data.slice(0, 4);
        }
        // this.lifePicture = data.data.filter((item) => {
        //       return item.typeId == this.lifeIndex;
        //     })[0].typePicture;
      });
    },
    getVigorously() {
      this.$getHttp("/pc/info3/getVigorously").then((data) => {
        if (data.code == 200) {
          // console.log("乡村",data);
          this.vigorouslyList = data.page.list.slice(0, 5);
        }
      });
    },
    getLaw() {
      this.$getHttp("/pc/info3/getLaw").then((data) => {
        if (data.code == 200) {
          // console.log("法律",data);
          this.lawList = data.page.list.slice(0, 5);
        }
      });
    },
    getOneScienceLeft(scienceType) {
      this.$getHttp("/pc/info3/getScienceLeft", {
        scienceType,
        ...this.pager,
      }).then((data) => {
        if (data.code == 200) {
          this.scienceleftList = data.data.list.slice(0, 3);
        }
      });
    },

    getScience() {
      let current = this.scienceTypesIndex * 3;
      this.$getHttp("/pc/info3/getScience", {
        scienceType: this.currentScienceType,
      }).then((data) => {
        if (data.code == 200) {
          // console.log("科学",data);
          // this.scienceTypes = data.data.type.slice(current, current + 3);
          this.scienceTypes = data.data.type;

          this.scienceList = data.data.list.slice(0, 9);

          // this.scienceleftList = data.data.list.slice(0, 3);
          this.getOneScienceLeft(this.currentScienceType);
        }
      });
    },
    getPictorial() {
      this.$getHttp("/pc/info3/getPictorial").then((data) => {
        if (data.code == 200) {
          console.log("画报", data);
          this.pictorialList = [...data.page.list];
          this.$nextTick(function () {
            setTimeout(() => {
              // https://blog.csdn.net/qq_41046662/article/details/88035800
              var mySwiper2 = new Swiper(".swiper-container-new2", {
                direction: "horizontal", // 水平切换选项    垂直vertical  默认是水平切换模式
                loop: true, // 循环模式选项
                autoplay: {
                  delay: 0,

                  disableOnInteraction: false,
                }, //可选选项，自动滑动
                speed: 3000,
                // pagination: {
                //   el: ".swiper-pagination2", //分页器 -
                // },
                slidesPerView: "auto", //设置slider容器能够同时显示的slides数量
                noSwiping: false, //设为true时，可以在slide上（或其他元素）增加类名'swiper-no-swiping'，使该slide无法拖动，希望文字被选中时可以考虑使用。该类名可通过noSwipingClass修改
                watchSlidesProgress: true, //开启这个参数来计算每个slide的progress(进度、进程)
                paginationClickable: false,
                slidesPerView: "4", //设置slider容器能够同时显示的slides数量
                spaceBetween: 9, //在slide之间设置距离 默认单位px 也可以设置% vw vh ....
                centeredSlides: false, //设定为true时，active slide会居中，而不是默认状态下的居左
                // slidesOffsetBefore: 60, //设定slide与左边框的预设偏移量 还有slidesOffsetAfter 反之
              });
            }, 200);
          });
        }
      });
    },
    getLifeNew() {
      this.$getHttp("/pc/info3/getLifeNew").then((data) => {
        if (data.code == 200) {
          // console.log("新生活",data);
          // .slice(0, 4)
          this.lifeNewList = data.page.list.slice(0, 5);
        }
      });
    },
    getLifePicture() {
      this.$getHttp("/pc/info3/getLifePicture").then((data) => {
        if (data.code == 200) {
          // console.log("生活图片",data);
          this.lifePicture = data.data.filter((item) => {
            return item.typeId == this.lifeIndex;
          })[0].typePicture;
        }
      });
    },
    getLife() {
      if (this.lifeIndex == 4) {
        this.$getHttp("pc/life/get").then((res) => {
          if (res.code == 200) {
            this.lifeList = res.data.lifeCounseling.slice(0, 5).map((item) => {
              return {
                id: item.id,
                time: item.lifeDate,
                title: item.lifeTitle,
                content: item.lifeContent,
              };
            });
            // console.log("生活",this.lifeList);
          }
        });
      } else {
        this.$getHttp("pc/life/getInfo", {
          lifeType: this.lifeIndex,
        }).then((res) => {
          if (res.code == 200) {
            // console.log('shenghuo',res.data);
            this.lifeList = res.data.slice(0, 5).map((item) => {
              return {
                ...item,
                id: item.id,
                time: item.lifeDate,
                title: item.lifeTitle,
                content: item.lifeContent,
              };
            });
          }
        });
      }
    },
    getPolicyPropaganda() {
      this.$getHttp("/pc/government/getAll").then((res) => {
        if (res.code == 200) {
          this.policyPropagandaList = res.data.policyPropaganda
            .slice(0, 5)
            .map((item) => {
              return {
                id: item.id,
                time: item.governmentDate,
                title: item.governmentTitle,
                content: item.governmentContent,
              };
            });
          // console.log("zz",this.policyPropagandaList);
        }
      });
    },
    getTerrace() {
      this.$getHttp("/pc/info3/getTerrace").then((data) => {
        if (data.code == 200) {
          // console.log("学习",data);
          this.terraceList = data.data.slice(0, 3);
        }
      });
    },
    getFeaturedList() {
      this.$getHttp("/pc/featured/getList", { pageNo: 1, pageSize: 10 }).then(
        (data) => {
          if (data.code == 200) {
            this.featuredList = data.data.list.slice(0, 10);
            // console.log("学习",data);
            // this.terraceList = data.data.slice(0, 3);
          }
        }
      );
    },
    getTomatoList() {
      this.$getHttp("/pc/tomato/getList", { pageNo: 1, pageSize: 10 }).then(
        (data) => {
          if (data.code == 200) {
            this.tomatoList = data.data.list.slice(0, 15);
            // console.log("学习",data);
            // this.terraceList = data.data.slice(0, 3);
            setTimeout(() => {
              // https://blog.csdn.net/qq_41046662/article/details/88035800
              this.mySwiper = new Swiper(".tomato-product-left-list", {
                // direction: "horizontal", // 水平切换选项    垂直vertical  默认是水平切换模式
                loop: true, // 循环模式选项

                autoplay: {
                  // 自动滑动

                  stopOnLastSlide: true,
                  speed: 2000,
                  delay: 4000, //5秒切换一次
                  disableOnInteraction: false,
                },
                grabCursor: true,
                // pagination: {
                //   el: ".swiper-pagination2", //分页器 -
                // },
                // slidesPerView: "auto", //设置slider容器能够同时显示的slides数量
                noSwiping: false, //设为true时，可以在slide上（或其他元素）增加类名'swiper-no-swiping'，使该slide无法拖动，希望文字被选中时可以考虑使用。该类名可通过noSwipingClass修改
                watchSlidesProgress: true, //开启这个参数来计算每个slide的progress(进度、进程)
                paginationClickable: false,
                slidesPerView: "3", //设置slider容器能够同时显示的slides数量
                // spaceBetween: 0, //在slide之间设置距离 默认单位px 也可以设置% vw vh ....
                // centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左
                // slidesOffsetBefore: 60, //设定slide与左边框的预设偏移量 还有slidesOffsetAfter 反之
              });
            }, 200);
          }
        }
      );
    },
  },
  created() {
    that = this;
    this.getInfo();
    this.getCarousel();
    this.getElegant();
    this.getPictureShow();
    this.getTerrace();
    this.getPolicyPropaganda();
    this.getVigorously();
    this.getLaw();
    this.getScience();
    this.getPictorial();
    this.getLife();
    this.getLifePicture();
    this.getLifeNew();

    this.getFeaturedList();
    this.getTomatoList();
    // 特色产品图片
    this.$getHttp("/pc/rotation/getRotation", {
      first: 1,
      sec: 4,
    }).then((data) => {
      if (data.code == 200) {
        // this.showImg1 = data.data.slice(0, 1);
        this.showImg1 = (
          data.data.filter((item) => {
            return item.rotationTypeSecond == 4;
          })[0] || {}
        ).rotationPictrue;
        // console.log("学习",data);
        // this.terraceList = data.data.slice(0, 1);
      }
    });

    // 特色西红柿图片
    this.$getHttp("/pc/rotation/getRotation", {
      first: 1,
      sec: 4,
    }).then((data) => {
      if (data.code == 200) {
        // this.showImg2 = data.data.slice(0, 1);
        this.showImg2 = (
          data.data.filter((item) => {
            return item.rotationTypeSecond == 5;
          })[0] || {}
        ).rotationPictrue;
        // this.featuredList = data.data.list;
        // console.log("学习",data);
        // this.terraceList = data.data.slice(0, 3);
      }
    });
    this.$getHttp("/pc/relevant/getInfo", {
      pageNo: 1,
      relevantTypeFirst: 1,
      pageSize: 10,
    }).then((res) => {
      var publicList = res.data.map((item) => {
        return {
          ...item,
          id: item.id,
          time: item.relevantDate,
          title: item.relevantTitle,
          content: item.relevantContent,
        };
      });
      this.publicnList = publicList.slice(0, 10);
      setTimeout(() => {
        // https://blog.csdn.net/qq_41046662/article/details/88035800
        this.mySwiper = new Swiper(".tomato-product-right-list", {
          direction: "vertical", // 水平切换选项    垂直vertical  默认是水平切换模式
          loop: true, // 循环模式选项

          autoplay: {
            // 自动滑动

            stopOnLastSlide: true,
            speed: 2000,
            delay: 4000, //5秒切换一次
            disableOnInteraction: false,
          },
          grabCursor: true,
          // pagination: {
          //   el: ".swiper-pagination2", //分页器 -
          // },
          // slidesPerView: "auto", //设置slider容器能够同时显示的slides数量
          noSwiping: false, //设为true时，可以在slide上（或其他元素）增加类名'swiper-no-swiping'，使该slide无法拖动，希望文字被选中时可以考虑使用。该类名可通过noSwipingClass修改
          watchSlidesProgress: true, //开启这个参数来计算每个slide的progress(进度、进程)
          paginationClickable: false,
          slidesPerView: "auto", //设置slider容器能够同时显示的slides数量
          // spaceBetween: 0, //在slide之间设置距离 默认单位px 也可以设置% vw vh ....
          // centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左
          // slidesOffsetBefore: 60, //设定slide与左边框的预设偏移量 还有slidesOffsetAfter 反之
        });
      }, 200);
    });

    AOS.init({
      offset: 10,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  },

  mounted() {},
  data() {
    return {
      importantNewsList: [],
      minshengExpressList: [],
      publicnList: [],
      showImg1: "",
      showImg2: "",
      latestNewsList: [],
      featuredList: [],
      tomatoList: [],
      scienceListLeft: [],
      transformY: 0,
      terraceList: [],
      pictureShowList: [],
      policyPropagandaList: [],
      lifeList: [],
      lifePicture: "",
      lifeTypes: [
        { id: 1, typeName: "生活服务" },
        { id: 2, typeName: "生活指南" },
        { id: 3, typeName: "智慧医疗" },
        { id: 4, typeName: "生活资讯" },
      ],
      lifeNewList: [],
      lifeIndex: 4,
      pictorialList: [],
      scienceleftList: [],
      scienceList: [],
      scienceTypesIndex: 0,
      scienceTypes: [
        { id: 1, typeName: "科技新闻" },
        { id: 2, typeName: "科技思想研究" },
        { id: 3, typeName: "5G时代" },
      ],
      currentScienceType: 1,
      lawList: [],
      vigorouslyList: [],
      carouselList3: [],
      searchKeyword: "",
      bgStyle: {
        backgroundImage: "url(" + require("@/assets/temp/main-bg.png") + ")",
      },
      categoryList: [
        {
          id: 10000,
          image: require("@/assets/image/home-ico1.png"),
          text: "政策宣传",
          style: { backgroundColor: "#4C86CF" },
        },
        {
          id: 10001,
          image: require("@/assets/image/home-ico2.png"),
          text: "调查问卷",
          style: {
            backgroundColor: "#B3D465",
          },
        },
        {
          id: 10002,
          image: require("@/assets/image/home-ico3.png"),
          text: "网上办事",
          style: { backgroundColor: "#F6B37F" },
        },
        {
          id: 10003,
          image: require("@/assets/image/home-ico4.png"),
          text: "智慧医疗",
          style: { backgroundColor: "#C490BF" },
        },
        {
          id: 10004,
          image: require("@/assets/image/home-ico5.png"),
          text: "农产供需",
          style: { backgroundColor: "#EDA7AD" },
        },
        {
          id: 10005,
          image: require("@/assets/image/home-ico6.png"),
          text: "农资供需",
          style: { backgroundColor: "#D1C0A5" },
        },
      ],
      publicList: [
        {
          title: "[特别关注]读您的心声，解您的心事“平读心声”公众号 重磅来临",
          id: 100,
          time: "2022-11-20 12:30",
        },
        {
          title: "系列税费支持政策落实情况怎么样? 看这场发布会!",
          id: 102,
          time: "2022-11-20 12:30",
        },
        {
          title: "系列税费支持",
          id: 1023,
          time: "2022-11-20 12:30",
        },
        {
          title: "系列税费支持",
          id: 1024,
          time: "2022-11-20 12:30",
        },
        {
          title: "系列税费支持",
          id: 1025,
          time: "2022-11-20 12:30",
        },
      ],
      partyList: [
        {
          title: "[特别关注]读您的心声，解您的心事“平读心声”公众号 重磅来临",
          id: 100,
          time: "2022-11-20 12:30",
        },
        {
          title: "系列税费支持政策落实情况怎么样? 看这场发布会!",
          id: 102,
          time: "2022-11-20 12:30",
        },
        {
          title: "系列税费支持",
          id: 1023,
          time: "2022-11-20 12:30",
        },
        {
          title: "系列税费支持",
          id: 1024,
          time: "2022-11-20 12:30",
        },
        {
          title: "系列税费支持",
          id: 1025,
          time: "2022-11-20 12:30",
        },
      ],
      navTitle: [
        { value: 0, label: "首页", path: "/home" },
        { value: 1, label: "政务服务", path: "/governmentServices" },
        { value: 2, label: "投资崔家集", path: "/investment" },
        { value: 3, label: "特色文化", path: "/characteristicCulture" },
        { value: 4, label: "智慧村庄", path: "/smartVillage" },
        { value: 5, label: "农需农供", path: "/agriculturalDemandAndSupply" },
        { value: 6, label: "农工供需", path: "/villagerDemandAndSupply" },
        { value: 7, label: "便民生活", path: "/convenientLife" },
        { value: 8, label: "农技学堂", path: "/agriculturalTechnologySchool" },
        { value: 9, label: "数字大屏", path: "/show", hidden: true },
      ],
      navIndex: 0,
      informationList: [
        {
          title: "[特别关注]读您的心声，解您的心事“平读心声”公众号 重磅来临",
          id: 11024,
          time: "2022-11-20 12:30",
        },
        {
          title: "系列税费支持政策落实情况怎么样? 看这场发布会!",
          id: 11025,
          time: "2022-11-20 12:30",
        },
        {
          title: "[特别关注]读您的心声，解您的心事“平读心声”公众号 重磅来临",
          id: 11026,
          time: "2022-11-20 12:30",
        },
        {
          title: "系列税费支持政策落实情况怎么样? 看这场发布会!",
          id: 11027,
          time: "2022-11-20 12:30",
        },
      ],
      carouselList2: [],
      carouselList: [
        // { id: 2008, image: require("@/assets/temp/carousel1副本.png") },
        // { id: 2009, image: require("@/assets/temp/carousel2.png") },
        // { id: 2010, image: require("@/assets/temp/carousel3.png") },
        // { id: 2011, image: require("@/assets/temp/carousel4.png") },
        // { id: 2012, image: require("@/assets/temp/carousel4.png") },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.main-home {
  position: relative;
  width: 100%;
  .log-label {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    line-height: 196px;
    font-size: 36px;
    color: white;
    font-weight: 600;
    text-align: center;
  }
  // 特色产品
  .feature-product {
    position: relative;

    .feature-product-log {
      width: 100%;
      // height: 136px;
      object-fit: cover;
      vertical-align: middle;
    }
    .feature-product-list {
      display: flex;
      // max-height: 312px;
      .feature-product-left {
        padding: 12px 12px 0px 12px;
        flex: 1;
        // flex-shrink: 0;
        border: 1px solid var(--theme-color);
        margin-top: -1px;
        display: flex;
        flex-wrap: wrap;
        .feature-product-left-item {
          width: 50%;
          height: 60px;
          // padding: 4px 12px;
          display: flex;
          margin-bottom: 6px;
          .feature-product-left-img {
            width: 64px;
            // height: 48px;
            flex-shrink: 0;
            // object-fit: cover;
          }
          .feature-product-left-content {
            padding: 0 12px;
            line-height: 24px;
            // max-height: 48px;
            cursor: pointer;
          }
        }
      }
      .feature-product-right {
        width: 36%;

        // flex-shrink: 0;
        border: 1px solid var(--theme-color);
        margin-top: -1px;
        margin-left: -1px;

        .feature-product-right-title {
          // cursor: pointer;
          user-select: none;
          font-size: 24px;
          background-color: #eaedf7;
          color: var(--theme-color);
          font-weight: 400;
          text-align: center;
          height: 50px;
          line-height: 50px;
        }
        .feature-product-right-content {
          padding: 0 16px;
          line-height: 53px;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
  .tomato-product {
    display: flex;
    margin-top: 32px;
    margin-bottom: 32px;
    .tomato-product-left {
      width: 800px;
      position: relative;
      flex-shrink: 0;
      .tomato-product-left-log {
        width: 100%;
        height: 100px;
        object-fit: cover;
        vertical-align: middle;
      }
      .tomato-product-left-list {
        margin-top: 24px;
        display: flex;
        width: 100%;
        height: 176px;
        overflow: hidden;
        .swiper-wrapper {
          position: relative;

          display: flex;
          height: 100%;
        }
        .tomato-product-left-item {
          // background-color: green;
          width: 33%;
          flex: 1;
          display: flex;
          flex-shrink: 0;
          align-items: center;
          flex-direction: column;

          .tomato-product-left-img {
            width: 76%;
            height: 136px;
          }
          .tomato-product-left-text {
            margin-top: 6px;
            width: 100%;
            user-select: none;
            font-size: 22px;
            color: #333;
            font-weight: 400;
            text-align: center;
            height: 28x;
            line-height: 28x;
          }
        }
      }
    }
    .tomato-product-right {
      margin-left: 30px;
      width: 100%;
      .news-title {
        // margin-top: 10px;
        border-bottom: 2px solid var(--theme-color);
        height: 46px;
        position: relative;
        .main-title {
          padding: 0 18px;
          color: #ffffff;
          background-color: var(--theme-color);
          height: 46px;
          line-height: 46px;
          vertical-align: center;
          font-size: 20px;
          color: #ffffff;
          position: absolute;
          left: 0;
          top: 0;
          &::after {
            display: none;
          }
        }
      }
      .tomato-product-right-list {
        overflow: hidden;
        width: 100%;
        height: 297px;
        .tomato-product-right-item {
          border-bottom: 1px solid #d3d3d3;
          padding-bottom: 20px;
          padding-top: 20px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 98px !important;
          .tomato-product-right-title {
            width: 300px;
            font-size: 18px;
            min-height: 56px;
            font-weight: 400;
            color: #333333;
            line-height: 28px;

            // max-height: 36px !important;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            // -webkit-box-orient: vertical;
          }
          .tomato-product-right-time {
            text-align: right;
            width: 33%;
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            line-height: 28px;
            align-self: flex-end;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .module-title {
    width: 100%;
    border-bottom: 2px solid var(--theme-color);
    height: 46px;
    position: relative;
    margin-bottom: 20px;
    .module-title-text {
      padding: 0 18px;
      height: 46px;
      line-height: 46px;
      vertical-align: center;
      font-size: 20px;
      color: #ffffff;
      background-color: var(--theme-color);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .advertisement {
    position: fixed;
    right: 3%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    .advertisement-img {
      cursor: pointer;
      width: auto;
      height: 303px;
      object-fit: contain;
    }
  }
  .main-bg-wrap {
    width: 100%;
    // height: 1138px;
    // background-color: #4d725c;
    position: relative;
    overflow: hidden;
    // padding-top: 465px;

    .main-bg-img-new {
      height: 260px;
      background: var(--theme-color);
      width: 100%;
      background-position: bottom center;
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 10px;
    }

    .search-wrap-new {
      margin: 0 auto;
      background: var(--theme-color);
      padding: 5px;
      width: 478px;
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      ::v-deep .el-input__inner {
        border: none !important;
      }

      ::v-deep .el-input-group__append {
        background-color: var(--theme-color) !important;
        border: none !important;
        color: #fff;
      }

      ::v-deep .el-input__inner {
        border-radius: 6px;
      }

      .search {
        width: 468px;
        ::v-deep .el-input-group__append {
          cursor: pointer;
        }
      }
    }
    .main-bg-img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 186%;
      width: 100%;
      height: 284px;
      // overflow: hidden;
      background-position: bottom center;
      background-size: cover;
      background-repeat: no-repeat;
      // border-bottom-left-radius: 50%;
      // border-bottom-right-radius: 50%;

      background-image: linear-gradient(180deg, #66ccff 0%, #41b5f0 100%);
      border-bottom-left-radius: 60% 15%;
      border-bottom-right-radius: 60% 15%;
    }

    .wraper-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title-list-wrap {
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 1296px;
        // min-width: 1295px;
        .title {
          cursor: pointer;
          position: relative;
          // margin: 0 12px;
          padding: 0 20px;
          line-height: 40px;
          font-size: 20px;
          font-weight: 400;
          color: var(--theme-color);
          border: 2px solid var(--theme-color);
          border-radius: 0px 0px 16px 16px;
          border-top: none;
          &.active {
            font-weight: 400;
            font-size: 24px;
            background: var(--theme-color);
            color: #ffffff;
            height: 54px;
            line-height: 54px;
            padding: 0 20px;
          }
        }
      }
    }

    .wraper3 {
      .title-list-wrap {
        margin-top: 34px;
        margin-bottom: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-width: 1295px;
        .title {
          cursor: pointer;
          position: relative;
          padding: 0 29px;
          height: 58px;
          line-height: 58px;
          font-size: 18px;
          font-weight: 400;
          color: #333;
          &.active {
            font-weight: bold;
            color: #ffba00;
          }
          &:not(:last-child)::after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 18px;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            background-color: #fff;
          }
        }
      }
    }
    .wraper3 {
      display: flex;
      .wraper-left {
        display: inline-block;
        vertical-align: middle;
        .carousel-wrap {
          width: 638px;
          height: 300px;
          margin-right: 20px !important;
          ::v-deep .el-carousel__arrow {
            background-color: var(--theme-color);
            color: #ffffff;
          }

          .carousel-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .category-wraper {
          // margin-top: 24px;
          width: 638px;
          display: flex;
          flex-wrap: wrap;
          padding: 0 70px;
          .category-item {
            cursor: pointer;
            width: 96px;
            height: 96px;
            border-radius: 8px;
            position: relative;
            margin: 6px 35px 20px;
            text-align: center;
            // background-image: url("../../assets/image/village-bg.png");
            background-size: cover;
            background-repeat: no-repeat;
            .category-img {
              margin-top: 15px;
              width: 40px;
              user-select: none;
            }
            .category-text {
              position: absolute;
              bottom: 16px;
              left: 50%;
              white-space: nowrap;
              transform: translateX(-50%);
              margin-top: 7px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              line-height: 16px;
            }
          }
        }
      }
      .wraper-right {
        // display: inline-block;
        // vertical-align: top;
        width: 638px;
        .news-title {
          margin-top: 10px;
          border-bottom: 2px solid var(--theme-color);
          height: 46px;
          position: relative;
          &::after {
            display: none;
          }
          .main-title {
            padding: 0 18px;
            color: #ffffff;
            background-color: var(--theme-color);
            height: 46px;
            line-height: 46px;
            vertical-align: center;
            font-size: 20px;
            color: #ffffff;
            position: absolute;
            left: 0;
            top: 0;
            &::after {
              display: none;
            }
          }
          .sub-title {
            color: var(--theme-color);
          }
        }
        .public-list {
          margin-bottom: 40px;
          .public-item {
            border-bottom: 1px solid #d3d3d3;
            padding-bottom: 20px;
            padding-top: 20px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .public-title {
              width: 70%;
              // height: 59px;
              font-size: 18px;
              min-height: 56px;
              font-weight: 400;
              color: #333333;
              line-height: 28px;
            }
            .public-time {
              text-align: right;
              width: 25%;
              font-size: 18px;
              font-weight: 400;
              color: #333333;
              line-height: 28px;
              align-self: flex-end;
            }
          }
        }
      }
    }

    .image-wraper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 20px;
      .image-full {
        width: 100%;
        height: 208px;
        background-color: var(--theme-color);
        margin-bottom: 20px;
        // display: none;
      }

      .image-half {
        display: block;

        width: 638px;
        height: 208px;
        background-color: var(--theme-color);
        margin-bottom: 20px;
      }
    }
  }

  .second-content {
    width: 100%;
    max-height: 374px;
    background-color: #ffffff;
    overflow: hidden;
    display: flex;
    margin-bottom: 40px;

    .wraper-left1,
    .wraper-right1 {
      width: 638px;
      margin-right: 20px;

      .content-title {
        width: 100%;
        font-size: 24px;
        background-color: #eaedf7;
        color: var(--theme-color);
        font-weight: 400;
        text-align: center;
        height: 50px;
        line-height: 50px;
        cursor: pointer;
      }
      .content-list {
        background-color: #fef7ec;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        width: 100%;

        padding: 2px 20px;
        // min-height: 324px;
        .content-item {
          font-weight: 400;
          height: 65px;
          line-height: 65px;
          display: flex;
          // margin: 18px 0;
          font-size: 18px;
          color: #333333;
          cursor: pointer;
          .title {
            width: 100%;
          }
          .time {
            margin-left: 53px;
            width: 169px;
            flex: none;
          }
        }
      }
    }
    .wraper-right1 {
      margin-right: 0;
      .content-list {
        background-color: transparent;
      }
    }

    .wraper-left {
      width: 638px;
      margin-right: 20px;

      .content-title {
        width: 100%;
        font-size: 24px;
        background-color: #eaedf7;
        color: var(--theme-color);
        font-weight: 400;
        text-align: center;
        height: 50px;
        line-height: 50px;
        cursor: pointer;
      }
      .content-list {
        background-color: #fef7ec;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: 2px 20px;
        min-height: 324px;
        .content-item {
          font-weight: 400;
          line-height: 28px;
          display: flex;
          margin: 18px 0;
          font-size: 18px;
          color: #333333;
          cursor: pointer;
          .title {
            width: 100%;
          }
          .time {
            margin-left: 53px;
            width: 169px;
            flex: none;
          }
        }
      }
    }

    .wraper-right {
      width: 638px;

      .content-title {
        cursor: pointer;
        width: 100%;
        font-size: 24px;
        background-color: #eaedf7;
        color: var(--theme-color);
        font-weight: 400;
        text-align: center;
        height: 50px;
        line-height: 50px;
      }
      .content-list {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        width: 100%;
        .content-item {
          font-weight: 400;
          line-height: 28px;
          display: flex;
          margin-top: 16px;
          font-size: 18px;
          color: #333333;
          cursor: pointer;
          .thumb {
            margin-right: 20px;
            width: 142px;
            height: 92px;
            flex: none;
            border: none;
            background-color: var(--theme-color);
          }
          .title {
            width: 100%;
          }
        }
      }
    }
  }

  .study-content {
    width: 100%;
    margin-bottom: 40px;
    .imgs-wraper {
      width: 100%;
      display: flex;
      margin-bottom: 40px;
      .wraper-img {
        width: 419px;
        height: 220px;
        // background-color: var(--theme-color);
        flex: none;
        margin-right: 20px;
      }
    }

    .study-container {
      display: flex;
      .study-wraper {
        width: 419px;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        .wraper-title {
          cursor: pointer;
          background-color: #eaedf7;
          color: var(--theme-color);
          height: 50px;
          line-height: 50px;
          font-size: 24px;
          width: 100%;
          text-align: center;
          margin-bottom: 20px;
        }

        .wraper-list {
          display: flex;
          flex-direction: column;
          padding: 0 20px;
          width: 100%;
          height: 260px;
          .list-item {
            margin-bottom: 20px;
            cursor: pointer;

            .title {
              line-height: 28px;
              font-size: 18px;
              width: 100%;
              height: 28px;
            }
          }
        }
      }
    }
  }

  .science-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    .science-container {
      display: flex;
      .imgs-wraper {
        display: flex;
        flex-direction: column;
        width: 309px;
        .wraper-img {
          width: 309px;
          height: 164px;
          flex: none;
          margin-bottom: 20px;
          background-position: bottom center;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;

          .img-text {
            // width: 100px;
            padding: 0 6px;
            height: 38px;
            font-size: 18px;
            background-color: #4f9ee5;
            color: #fff;
            line-height: 38px;
            text-align: center;
            position: absolute;
            left: 0;
            bottom: 0;
            border-top-right-radius: 10px;
          }
        }
      }
      .science-list {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        padding: 2px 20px;
        width: 678px;
        max-height: 532px;

        .list-item {
          margin-bottom: 28px;
          cursor: pointer;
          .title {
            line-height: 34px;
            font-size: 20px;
            width: 100%;
          }
        }
      }

      .science-switch {
        display: flex;
        flex-direction: column;
        width: 309px;
        .switch-btn {
          width: 100%;
          height: 109px;
          cursor: pointer;
        }
        .science-bg {
          width: 100%;
          height: 315px;
          overflow: hidden;
          background: url("@/assets/image/science-bg.png");
          background-position: bottom center;
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
          .science-bg-wrap {
            transition: all 0.3s;
          }
          .switch-item {
            height: 105px;
            line-height: 105px;
            flex-shrink: 0;
            font-size: 28px;
            cursor: pointer;
            &.active {
              color: var(--theme-color);
            }
          }
        }
      }
    }
  }

  .pictorial-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    .pictorial-container {
      width: 1296px;
      height: 250px;
      // overflow: hidden;
      // display: flex;
      .pictorial-wrapper2 {
        display: flex;
        position: relative;
        width: 100%;
        .pictorial-wraper {
          // flex-shrink: 0;
          width: 419px;
          display: flex;
          flex-direction: column;
          margin-right: 20px;
          align-items: center;
          &:last-child {
            margin-right: 0 !important;
          }
          .pictorial-img {
            width: 419px;
            height: 220px;
            // flex: none;
          }
          .pictorial-title {
            font-size: 18px;
            margin-top: 10px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .life-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    .life-container {
      display: flex;
      .life-tabs {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        .life-tab {
          cursor: pointer;
          width: 199px;
          height: 50px;
          border: 1px solid var(--theme-color);
          font-size: 20px;
          line-height: 50px;
          text-align: center;
          color: var(--theme-color);
          font-weight: 400;
          margin-bottom: 20px;
          &.active {
            background-color: var(--theme-color);
            color: #ffffff;
          }
        }
      }

      .life-titles {
        width: 637px;
        height: 260px;
        border: 1px solid var(--theme-color);
        padding: 12px 20px;
        margin-right: 21px;

        &:last-child {
          margin-bottom: 0 !important;
        }
        .list-item {
          cursor: pointer;
          margin-bottom: 24px;
          font-size: 18px;
          height: 28px;
          line-height: 28px;
        }
      }
      .life-thumb {
        width: 419px;
        height: 260px;
      }
    }

    .imgs-wraper {
      display: flex;
      .img-item {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        align-items: center;
        cursor: pointer;
        .img {
          width: 309px;
          height: 220px;
          margin-bottom: 10px;
        }

        .title {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }

  .bottom-wrap {
    .news-title {
      margin-top: 70px;
      &::after {
        display: none;
      }
    }
  }
  .bottom-content {
    margin: 0 auto 173px;
    .swiper-container-new2,
    .swiper-container-new {
      width: 100%;
      height: 206px;
      overflow: hidden;
      .swiper-wrapper {
        position: relative;
        width: 100%;

        height: 100%;
        // white-space: nowrap;
        display: flex;
        .swiper-slide {
          display: inline-block;
          vertical-align: top;
          width: 309px;
          width: calc((100% - 27px) / 4);
          height: 100%;
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;

          flex-shrink: 0;
        }
      }
    }
  }
}
.swiper-wrapper {
  /* 这里是改变其动画效果为匀速形式，不加此样式的话会造成滚动卡顿，看起来效果不平滑 */
  /* 样式做了各种浏览器的兼容 */
  transition-timing-function: linear !important;
  -webkit-transition-timing-function: linear !important;
  -moz-transition-timing-function: linear !important;
  -ms-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
</style>
