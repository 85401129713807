<template>
  <div class="xcfooter">
    <div class="bottom-wraper">
      <div class="wraper2 d-flex j-sb">
        <div class="bottom-text-wraper">
          <span class="bottom-text" @click="operate(1)">关于我们</span>
          <span class="bottom-text" @click="operate(2)">联系我们</span>
          <span class="bottom-text" @click="operate(3)">意见反馈</span>
          <span class="bottom-text" @click="operate(4)">隐私说明</span>
        </div>
        <div>
          <div class="bottom-qrcode-wrap" style="margin-right: 12px">
            <div class="bottom-qrcode">
              <img src="@/assets/ad.png" alt="" />
            </div>
            <div class="bottom-qrcode-text">安卓APP下载</div>
          </div>
          <div class="bottom-qrcode-wrap" style="margin-right: 12px">
            <div class="bottom-qrcode">
              <img src="@/assets/ios.png" alt="" />
            </div>
            <div class="bottom-qrcode-text">IOS APP下载</div>
          </div>
          <div class="bottom-qrcode-wrap">
            <div class="bottom-qrcode">
              <img src="@/assets/wx.jpg" alt="" />
            </div>
            <div class="bottom-qrcode-text">小程序下载</div>
          </div>
        </div>
      </div>
    </div>
    <div class="record-wraper">
      <a
        target="_blank"
        href=" "
        style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
          vertical-align: middle;
        "
        @click="openUrl('https://www.beian.gov.cn/portal/registerSystemInfo')"
      >
        <img src="@/assets/batb.png" style="float: left; vertical-align: top" />
        <p
          style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #ffffff;
          "
        >
          鲁公安备 37020602000287号
          <!-- 鲁ICP备37028302000853号 -->
        </p></a
      >
      <a
        target="_blank"
        href=" "
        style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
          vertical-align: middle;
        "
      >
        <!-- <img
          src="@/assets/656ce3ec-48b1-492d-864d-840a81e34c4e.jpg"
          style="float: left"
        /> -->
        <p
          style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 15px;
            color: #ffffff;
          "
          @click="openUrl('https://beian.miit.gov.cn/')"
        >
          <!-- 信息产业部备案： -->
          鲁ICP备20024604号-8
          <!-- 鲁ICP备37028302000853号 -->
        </p></a
      >
      <!-- 信息产业部备案:鲁ICP备xx00000x号 -->
    </div>
    <el-dialog title="意见反馈" :visible.sync="dialogVisible" width="680px">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item prop="feedbackContent">
          <WangEditor ref="wang" v-model="form.feedbackContent"></WangEditor>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  methods: {
    operate(i) {
      switch (i) {
        case 1:
          this.toArticle(3);
          break;
        case 2:
          this.toArticle(4);
          break;
        case 3:
          break;
        case 4:
          this.toArticle(2);
          break;
        default:
          break;
      }
      if (i == 3) {
        this.dialogVisible = true;
        setTimeout(() => {
          // console.log(this.form.feedbackContent, "前");
          this.$refs.form.resetFields();
          // console.log(this.form.feedbackContent, "后");
          // this.$refs.form.clearValidate();
          this.$refs.wang.init("");
          // console.log(this.form.feedbackContent, "后");
        });
      }
    },
    cancel() {
      this.dialogVisible = false;
    },
    submit() {
      if (!this.userInfo) {
        return false;
      }
      this.form.feedbackUserId = this.userInfo.id;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$postHttp("/pc/feedback/feedback", this.form)
            .then((res) => {
              if (res.code == 200) {
                this.mySuccessMessage(res.msg);
                this.dialogVisible = false;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {},
  },
  data() {
    var validateContent = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入内容"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      form: {
        feedbackContent: "",
        feedbackUserId: "",
      },
      rules: {
        feedbackContent: [{ validator: validateContent, trigger: "blur" }], //姓名：
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.xcfooter {
  position: relative;

  .bottom-wraper {
    height: 160px;
    background-color: var(--theme-color);
    // display: flex;
    .bottom-text-wraper {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 160px;

      display: inline-block;
      vertical-align: middle;
      .bottom-text {
        &:hover {
          opacity: 1;
        }
        opacity: 1;
        cursor: pointer;
        margin-right: 82px;
      }
    }
    .bottom-qrcode-wrap {
      margin-left: auto;
      padding-top: 20px;
      width: 160px;
      text-align: right;
      display: inline-block;
      vertical-align: top;
      .bottom-qrcode {
        text-align: center;
        img {
          width: 92px;
          height: 92px;
        }
      }
      .bottom-qrcode-text {
        text-align: center;
        height: 18px;
        font-size: 18px;
        margin-top: 12px;
        font-weight: 400;
        color: #ffffff;
        // line-height: 55px;
        opacity: 1;
      }
    }
  }
  .record-wraper {
    text-align: center;
    width: 100%;
    background-color: var(--theme-color);
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 43px;
  }
}
</style>
