<template>
  <div class="xchead">
    <div class="img-wrap">
      <img :src="bg" alt="" srcset="" />
      <head-top></head-top>

      <div class="xchead-title-wrap">
        <div
          class="xchead-title"
          :class="curIndex == item.value ? 'active' : ''"
          v-for="item in navTitle"
          :key="item.value"
          @click="selectTitle(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headTop from "./headTop";

export default {
  components: {
    headTop,
  },
  data() {
    return {
      navTitle: [
        { value: 0, label: "首页", path: "/home" },
        { value: 1, label: "政务服务", path: "/governmentServices" },
        { value: 2, label: "投资崔家集", path: "/investment" },
        { value: 3, label: "特色文化", path: "/characteristicCulture" },
        { value: 4, label: "智慧村庄", path: "/smartVillage" },
        { value: 5, label: "农需农供", path: "/agriculturalDemandAndSupply" },
        { value: 6, label: "农工供需", path: "/villagerDemandAndSupply" },
        { value: 7, label: "便民生活", path: "/convenientLife" },
        { value: 8, label: "农技学堂", path: "/agriculturalTechnologySchool" },
      ],
      curIndex: 0,
    };
  },
  watch: {
    $route: {
      handler(newV, oldV) {
        console.log(newV);
        if (newV) {
          if (newV.fullPath == "/") {
            this.curIndex = 0;
            return;
          }
          var arr = this.navTitle.filter(
            (item) => newV.fullPath.indexOf(item.path) > -1
          );
          if (arr.length > 0) {
            this.curIndex = arr[0].value;
          } else {
            console.error("未匹配");
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    selectTitle(item) {
      this.$router.push({
        path: item.path,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.xchead {
  position: relative;
  .img-wrap {
    position: relative;
    height: 330px;
    width: 100%;
    overflow: hidden;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .head-operate-wrap {
      position: relative;
      margin-top: 25px;
      height: 35px;
      width: 1250px;
      .head-operate {
        line-height: 35px;

        font-size: 20px;
        color: #000000;
        font-weight: bold;
        position: absolute;
        top: 0;
        right: 0px;
        // left: 22px;
        vertical-align: text-bottom;

        .head-operate-item {
          width: 128px;
          height: 35px;
          line-height: 35px;
          display: inline-block;
          vertical-align: middle;
          background: #ffa200;
          border-radius: 5px;
          margin-right: 32px;
          text-align: center;
          color: #ffffff;
          .head-operate-img {
            position: relative;
            margin-left: -2px;
            margin-right: 4px;
            width: 19px;
            height: 19px;
          }
        }
        .head-login {
          color: #000000;
          margin-right: 13px;
        }
        .head-register {
          color: #000000;
          margin-right: 13px;
          margin-left: 13px;
        }
      }
    }

    .xchead-title-wrap {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1295px;
      height: 58px;
      line-height: 58px;
      font-size: 20px;
      background-color: var(--theme-color);

      border-radius: 10px 10px 0px 0px;
      .xchead-title {
        display: inline-block;
        font-weight: 400;
        color: #ffffff;
        padding: 0 30px 0 31px;
        position: relative;
        cursor: pointer;
        &:first-child {
          margin-left: 20px;
        }
        &:not(:last-child) {
          &::after {
            content: "";
            display: block;
            position: absolute;
            right: -0.5px;
            top: 50%;
            height: 18px;
            width: 1px;
            transform: translateY(-50%);
            background-color: #fff;
          }
        }
      }
      .active {
        font-weight: bold;
        color: #ffa200;
      }
    }
  }
}
</style>
