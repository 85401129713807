<template>
  <div id="app">
    <complaint ref="complaint" v-if="showComplaint2"></complaint>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/user">About</router-link>
    </nav> -->

    <router-view />
  </div>
</template>

<script>
import complaint from "@/components/complaint.vue";
export default {
  data() {
    return {
      showComplaint2: false,
    };
  },
  components: {
    complaint,
  },
  methods: {},
  watch: {
    showComplaint(newV) {
      if (newV) {
        console.log(newV, newV);
        this.showComplaint2 = newV;
      }
    },
  },
};
</script>
<style lang="scss">
:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --theme-color: #d22d20;
}
// #4d725c
@import url(@/assets/css/style.css);
@import url(@/assets/css/cover.css);

.show-select {
  .el-select-dropdown {
    background-color: #082761 !important;
    border: 1px solid #90b7ff !important;
  }
  .el-select-dropdown__item {
    color: #ffffff !important;
    &:hover {
      background-color: #90b7ff !important;
    }
    &.selected {
      background-color: #90b7ff !important;
    }
  }
  .popper__arrow::after {
    border-bottom-color: #90b7ff !important;
  }
}
// .show-main {
//   .el-select-dropdown {
//     background-color: #082761 !important;
//     border: 1px solid #90b7ff !important;
//   }
//   .el-select-dropdown__item {
//     color: #ffffff !important;
//     &:hover {
//       background-color: #90b7ff !important;
//     }
//     &.hover {
//       background-color: #90b7ff !important;
//     }
//   }
//   .popper__arrow::after {
//     border-bottom-color: #90b7ff !important;
//   }
// }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}

.wraper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.wraper2 {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}
.wraper3 {
  max-width: 1296px;
  width: 100%;
  margin: 0 auto;
}
.min-height {
  min-height: calc(100vh - 533px);
}
.m-auto {
  margin: 0 auto;
}
.line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.line2 {
  word-break: break-all;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-center {
  text-align: center;
}
.mt-3 {
  margin-top: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.ml-auto {
  margin-left: auto;
}
.d-flex {
  display: flex;
}

.a-center {
  align-items: center;
}

.j-center {
  justify-content: center;
}

.j-sb {
  justify-content: space-between;
}
</style>
