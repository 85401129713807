import { split } from "lodash";
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      bg: (state) => state.user.bg,
      bg2: (state) => state.user.bg2,
      msgCount: (state) => state.user.msgCount,
      showComplaint: (state) => state.user.showComplaint,
    }),
  },
  data() {
    return {
      pager: {
        pageNo: 1,
        pageSize: 5,
      },
      imgUrl: this.$imgUrl,
      xccount: 0,
    };
  },watch:{
    '$route':{
      handler(newV,oldV){
        console.log('newV',newV);
        console.log('oldV',oldV);

      },
      immediate:true,
      deep:true,
    }
  },
  methods: {
    ...mapMutations(["LOGIN", "LOGOUT", "SETBG", "SETBG2", "SETMSG"]),
    // ...mapMutations("user", ["LOGIN", "LOGOUT"]),
    // ...mapMutations(["user/LOGIN2", "user/LOGOUT2"]),

    back() {
      // this.$router.back();
      let origineHref = this.store.get("origineHref");
      // console.log("origineHref", origineHref);
      // return;
      window.close()
      // if (origineHref) {
      //   this.openUrl(origineHref, "_self");
      // } else {
      //   this.$router.go(-1);
      // }
    },
    goArticle(item, cb) {
      if (window.getSelection().toString() != "") return;
      if (typeof item == "object") {
        console.log(item);

        var item = [item].map((item) => {
          return {
            id: item.id,
            isFeature: item.isFeature || "",
            title:
              item.title ||
              item.cultureTitle ||
              item.infoTitle ||
              item.rotationTitle ||
              item.elegantTitle ||
              item.vigorouslyTitle ||
              item.scienceTitle ||
              item.lawTitle ||
              item.showTitle ||
              item.lifeNewTitle,
            content:
              item.content ||
              item.cultureContent ||
              item.infoContent ||
              item.rotationContent ||
              item.elegantContent ||
              item.vigorouslyContent ||
              item.lawContent ||
              item.scienceContent ||
              item.lifeNewContent ||
              item.showContent ||
              "",
            time:
              item.time ||
              item.cultureDate ||
              item.infoDate ||
              item.elegantDate ||
              item.vigorouslyDate ||
              item.lawDate ||
              item.lifeNewDate ||
              item.showDate ||
              item.scienceDate,

            workerType: item.workerType || "",

            favoriteArticleId: item.favoriteArticleId || "",
            typeFirst: item.relevantTypeFirst,
            typeSecond: item.relevantTypeSecond,
            assets: item.assets || item.relevantAssets || "",
            picture:
              item.picture ||
              item.relevantPicture ||
              item.rotationPictrue ||
              item.infoPicture ||
              item.smartPicture ||
              item.lifePicture ||
              item.studyPicture ||
              item.escalationPicture ||
              item.elegantPicture ||
              item.sciencePicture ||
              item.lifeNewPicture ||
              item.showPicture ||
              "",
            phone: item.phone || item.relevantPhone || "",
            // 轮播图片   公告通知
            // rotationPictrue  infoPicture  smartPicture
          };
        });
        this.store.set("xcArticle", item[0]);
      }
      if (cb) {
        cb();
        return false;
      }
      // console.log(item);
      // return;
      // 默认路径
      var path = "/service/article2";
      let name = "article2";
      if (item[0].typeFirst || item[0].workerType) {
        path = "/service/article";
        name = "article";
      }

      let route2 = this.$router.resolve({
        name: name, //这里是跳转页面的name
        query: {
          id: item[0].id, //要传的参数
        },
      });

      const { href } = this.$router.resolve({
        path: this.$route.fullPath, //这里是跳转页面的name
      });
      // console.log(this.$route.fullPath);
      // console.log(href);

      this.store.set("origineHref", href);
      // return;
      window.open(route2.href, "_blank");

      // this.$router.push({
      //   path: path,
      //   query: {
      //     id: item[0].id,
      //   },
      // });
    },
    myMessage() {},
    // 隐私政策接口
    toArticle(type, newForm = "_blank") {
      this.$getHttp("/pc/other/getOther", { otherType: type || 1 }).then(
        (res) => {
          if (res.code == 200) {
            this.goArticle(
              {
                id: res.data.id,
                title: res.data.title,
                content: res.data.otherContent,
                time: res.data.time,
              },
              () => {
                let routeUrl = this.$router.resolve({
                  path: "/service/article2",
                  query: { id: res.data.id, mode: 1 },
                });
                this.openUrl(routeUrl.href);
              }
            );
          }
        }
      );
    },

    checkLoginSilent(cab) {
      return this.checkLogin(true, cab);
    },
    checkLogin(slience = false, callback) {
      if (this.loginStatus()) {
        // 登录下判断是否冻结
        if (this.userInfo.userState == 2) {
          //一定弹出冻结弹窗
          this.myErrorMessage("您的账号已冻结");
          return false;
        }
        callback && callback();
      } else {
        // 未登录下
        if (!slience) {
          // 弹出提示
          this.myErrorMessage("请先登录");
        }
      }

      return this.loginStatus();
    },

    loginStatus() {
      return !!this.userInfo && localStorage.getItem("xcuserInfo");
    },
    sleep(time = 2000) {
      return new Promise((reslove, reject) => {
        setTimeout(() => {
          reslove();
        }, time);
      });
    },
    goPath(path, type = "push") {
      this.$router[type]({
        path: path,
      });
    },
    goNewPath(path, query = {}, type = "_blank") {
      if (window.getSelection().toString() != "") return;

      let temp = this.$router.resolve({
        path: this.$route.fullPath, //这里是跳转页面的name
      });
      this.store.set("origineHref", temp.href);

      if (typeof path != "string") {
        const { href } = this.$router.resolve(path);
        window.open(href, "_blank"); // _blank 打开新的页面
      } else {
        const { href } = this.$router.resolve({
          path: `${path}`,
          query: { ...query },
        });
        window.open(href, type); // _blank 打开新的页面
      }
    },
    openUrl(routeUrl, type = "_blank") {
      window.open(routeUrl, type);
    },

    handleCurrentChange(e) {
      console.log("handleCurrentChange");
      this.pager.pageNo = e;
      this.getData();
    },
    getInitData() {
      this.pager.pageNo = 1;
      this.getData();
    },
    mySuccessMessage(title) {
      this.$message({
        message: title,
        type: "success",
        offset: 120,
        duration: 2000,
      });
    },
    myErrorMessage(title) {
      this.$message({
        message: title,
        type: "error",
        offset: 120,
        duration: 2000,
      });
    },
    imgAddPrefix(data) {
      if (typeof data == "object") {
        for (let key in data) {
          var element = data[key];
          if (Array.isArray(element)) {
            element.map((item) => {
              if (
                item.hasOwnProperty("infoPicture") &&
                item.infoPicture.trim()
              ) {
                item.infoPicture = this.$imgUrl + item.infoPicture;
              }
            });
          }
        }
      }
    },
    flattenLoop(arr) {
      let result = [];
      for (var i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
          result = result.concat(this.flattenLoop(arr[i]));
        } else {
          result.push(arr[i]);
        }
      }
      return result;
    },
    imgSplit(element) {
      if (Array.isArray(element)) {
        element.map((item) => {
          if (item.hasOwnProperty("rotationPictrue")) {
            item.imgArr =
              item.rotationPictrue.length > 0
                ? item.rotationPictrue.split("|").map((item2) => {
                    return this.$imgUrl + item2;
                  })
                : [];
          }
        });
      }
    },

    /**
     * 表单对象赋值:
     * 对目标对象存在且源对象同样存在的属性，全部覆盖；
     * 目标对象不存在但是源对象存在的属性， 全部丢弃；
     * 目标对象存在但是源对象不存在的属性，保留目标对象的属性不做处理
     */
    recoverNotNull(target, source) {
      if (target === undefined || target === null) {
        throw new TypeError("Cannot convert first argument to object");
      }
      var to = Object(target);
      if (source === undefined || source === null) {
        return to;
      }
      var keysArray = Object.keys(Object(target));
      for (
        var nextIndex = 0, len = keysArray.length;
        nextIndex < len;
        nextIndex++
      ) {
        var nextKey = keysArray[nextIndex];
        var desc = Object.getOwnPropertyDescriptor(target, nextKey);
        if (desc !== undefined && desc.enumerable) {
          if (to.hasOwnProperty(nextKey)) {
            if (to[nextKey] instanceof Array) {
              to[nextKey] = source[nextKey];
            } else if (to[nextKey] instanceof Object) {
              recover(to[nextKey], source[nextKey]);
            } else if (source[nextKey] !== undefined) {
              to[nextKey] = source[nextKey];
            }
          }
        }
      }
      return to;
    },
  },
};
