<template>
  <div class="xchead wraper3">
    <template v-if="isHome">
      <div style="height: 78px"></div>
      <div class="head-operate-new">
        <img src="@/assets/image/village-logo.png" alt="" class="head-logo" />
        <img src="@/assets/image/slogan.png" alt="" class="head-thumb" />
        <span class="head-gohome" v-if="isWhite" @click="goPath('/home')"
          >回到首页</span
        >
        <div class="head-operate-item" @click="release">我要发布</div>
        <template v-if="!isLogin">
          <span class="head-operate-item" @click="open('login')">登录</span>
          <span class="head-operate-item" @click="open('register')">注册</span>
        </template>

        <template v-else>
          <div class="new-top">
            <span class="user-my" @click="goUser">我的</span>
            <span class="user-logout" @click="LOGOUT">退出</span>
            <div
              class="head-news-wrap"
              style="margin-left: 32px"
              @click="goNews"
            >
              <img
                class="head-news-icon"
                src="@/assets/image/news-ico2.png"
                alt=""
              />
              <div class="head-news-dot" v-if="msgCount && msgCount != 0"></div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="head-operate-wrap wraper3">
        <div
          class="head-operate"
          :style="'color:' + (isWhite ? '#ffffff' : '#000000')"
        >
          <span class="head-gohome" v-if="isWhite" @click="goPath('/home')"
            >回到首页</span
          >
          <div class="head-operate-item high-item" @click="release">
            <img
              src="@/assets/image/publish-ico.png"
              class="head-operate-img"
              alt=""
              srcset=""
            />
            我要发布
          </div>
          <template v-if="!isLogin">
            <span class="head-login" @click="open('login')">登录</span>|
            <span class="head-register" @click="open('register')">注册</span>
          </template>
          <template v-else>
            <img
              src="@/assets/user/user.png"
              alt=""
              class="user-icon"
              @click="goUser"
            /><span class="user-my" @click="goUser">我的</span
            ><img
              src="@/assets/user/logout.png"
              alt=""
              class="user-icon"
              @click="LOGOUT"
            /><span class="user-logout" @click="LOGOUT">退出</span>
          </template>
          <div
            class="head-news-wrap"
            style="margin-left: 32px"
            @click="goNews"
            v-show="showNews"
          >
            <img
              class="head-news-icon"
              src="@/assets/image/news-ico.png"
              alt=""
            />
            <div class="head-news-dot" v-if="msgCount && msgCount != 0"></div>
          </div>
        </div>
      </div>
    </template>
    <!-- v-drag2 -->
    <div class="infomation-wrap" v-show="!showNews && !isNotification">
      <div class="img-wrap" @click="goNews">
        <img src="@/assets/user/new2s.png" alt="" />
        <div class="dot" v-if="msgCount && msgCount != 0"></div>
      </div>
      <a @click="goNews" style="user-select: none">消息通知</a>
    </div>
    <login ref="login"></login>
  </div>
</template>
<script>
import login from "./login.vue";
const reg = /(.*?)<(\w+?)[^>]*?>(.*?)<\/\2>([^<]*)/gi;
const regn = /(.*?)<(\w+?)[^>]*?>(.*?)<\/\2>([^<]*)/i;
export default {
  components: {
    login,
  },
  props: {
    showNews: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHome: false,
      isLogin: false,
      isNotification: false,
      isWhite: false,
    };
  },
  created() {
    // stackoverflow
    // https://www.coder.work/article/3925914

    // XRegExp库
    // https://www.it1352.com/2633318.html
    // 问题描述
    // 示例字符串:$${a},{s$${d}$$}$$

    // 我想先匹配 $${d}$$ 并替换它一些文本，这样字符串就会变成 $${a},{sd}$$，然后$${a},{sd}$$ 将被匹配.
    var matchRecursive = (function () {
      var formatParts = /^([\S\s]+?)\.\.\.([\S\s]+)/,
        metaChar = /[-[\]{}()*+?.\\^$|,]/g,
        escape = function (str) {
          return str.replace(metaChar, "\\$&");
        };

      return function (str, format) {
        var p = formatParts.exec(format);
        if (!p)
          throw new Error(
            "format must include start and end tokens separated by '...'"
          );
        if (p[1] == p[2])
          throw new Error("start and end format tokens cannot be identical");

        var opener = p[1],
          closer = p[2],
          /* Use an optimized regex when opener and closer are one character each */
          iterator = new RegExp(
            format.length == 5
              ? "[" + escape(opener + closer) + "]"
              : escape(opener) + "|" + escape(closer),
            "g"
          ),
          results = [],
          openTokens,
          matchStartIndex,
          match;

        do {
          openTokens = 0;
          while ((match = iterator.exec(str))) {
            if (match[0] == opener) {
              if (!openTokens) matchStartIndex = iterator.lastIndex;
              openTokens++;
            } else if (openTokens) {
              openTokens--;
              if (!openTokens)
                results.push(str.slice(matchStartIndex, match.index));
            }
          }
        } while (openTokens && (iterator.lastIndex = matchStartIndex));

        return results;
      };
    })();

    if (this.checkLoginSilent()) {
      this.getMsg();
      this.updateUser();
    }
    //
    var str = `<span style="color:red">我是被保留的<a>111</a>我是被保留的</span><a href="asdasd">你好，我也是被保留的<font>我的font里的</font></a>`;
    console.log(matchRecursive("<|t<e></e><><|s|>t|)", "<...>"));
    return;
    // var str = `我是被保留的<a>111</a>我是被保留的`;
    var str1 = `title`;
    var reg1 = /(<\/?font.*?>)/gi;
    var reg2 = /(<\/?span.*?>)/gi;
    var reg3 = /(<\/?a.*?>)/gi;
    var reg4 = /(<\/?font.*?>)|(<\/?span.*?>)|(<\/?a.*?>)/gi;
    // var reg = /(.*?)<(\w+?)[^>]*?>(.*?)<\/\2>(.*?)/gi;
    // var reg = /(<\/?\w+>)\1/g;
    // console.log(str.replace(reg1, ""));
    // console.log(str.replace(reg2, ""));
    // console.log(str.replace(reg3, ""));
    // console.log(str.replace(reg4, ""));
    console.log("正则匹配", str.match(reg));
    // console.log("正则匹配", reg.exec(str));
    // console.log("正则匹配", reg.exec(str));
    // var targetStr = this.getStr(str);
    // console.log(targetStr);
    var temp;
    var str1 = "";
    var arr = str.match(reg);
    while ((temp = arr.shift())) {
      str1 += this.getStr(temp);
    }
    console.log(str1);
    // var tempStr = str;
    // var tempArr = [];
    // while ((tempArr = reg.exec(tempStr))) {
    //   tempArr[1]++tempArr[3];
    // }
  },

  methods: {
    getStr(tempStr) {
      let res = regn.exec(tempStr);
      if (res) {
        console.log("进", res[3]);
        return res[1] + this.getStr(res[3]) + res[4];
      } else {
        console.log("出", res);
        return tempStr;
      }
    },
    goNews() {
      if (this.checkLogin()) {
        this.goNewPath("/service/notification");
      }
    },
    updateUser() {
      this.$getHttp("/pc/user/selectOne", {
        id: this.userInfo.id,
      }).then((res) => {
        if (res.code == 200) {
          this.LOGIN(res.data);
        }
      });
    },
    goUser() {
      this.goNewPath("/user");
      // this.$router.push({
      //   path: "/user",
      // });
    },
    release() {
      if (this.checkLogin()) {
        this.goNewPath("/release");
        // this.$router.push({
        //   path: "/release",
        // });
      }
    },
    open(type) {
      this.$refs.login.open(type);
    },
    getMsg() {
      this.$getHttp("/pc/news/getUnreadNewsState", {
        userId: this.userInfo.id,
      }).then((res) => {
        if (res.code == 200) {
          this.SETMSG(res.count);
        }
      });
    },
  },
  watch: {
    userInfo: {
      handler(newV, oldV) {
        this.isLogin = !!newV;
      },
      immediate: true,
    },
    $route: {
      handler(newV, oldV) {
        console.log(newV);
        if (newV.fullPath.indexOf("notification") > -1) {
          this.isNotification = true;
        }
        if (/user|service|release/.test(newV.path)) {
          this.isWhite = true;
          console.log(
            this.isWhite,
            "this.isWhitethis.isWhitethis.isWhitethis.isWhitethis.isWhitethis.isWhitethis.isWhite"
          );
        }
        if (/home/.test(newV.path)) {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.xchead {
  position: relative;
  padding-top: 20px;
  .new-top {
    display: flex;
    align-items: center;
  }

  .head-operate-new {
    font-size: 20px;
    color: #000000;
    font-weight: bold;
    margin-top: -10px;
    position: absolute;
    right: 0;
    top: 23px;
    display: flex;
    // justify-content: flex-end;
    // align-items: center;
    // flex-wrap: nowrap;

    .head-logo {
      width: 81px;
      height: 81px;
      margin-right: 12px;
      display: inline-block;
    }
    .head-thumb {
      width: 666px;
      // height: 90px;
      margin-right: 192px;
      display: inline-block;
    }

    .head-operate-item {
      cursor: pointer;
      // height: 34px;
      line-height: 76px;
      vertical-align: middle;
      margin: 0 18px;
      text-align: center;
      color: #d22d20;
      display: inline-block;
    }
    .head-gohome {
      cursor: pointer;
      margin-right: 45px;
    }
    .user-icon {
      margin-right: 12px;
      height: auto;
      width: 20px;
    }
    .user-my {
      cursor: pointer;
      height: 34px;
      line-height: 28px;
      vertical-align: middle;
      text-align: center;
      color: #d22d20;
      display: inline-block;
      margin-right: 36px;
      margin-left: 18px;
    }
    .user-logout {
      cursor: pointer;
      height: 34px;
      line-height: 28px;
      vertical-align: middle;
      text-align: center;
      color: #d22d20;
      display: inline-block;
    }
  }
  .head-operate-wrap {
    position: relative;

    height: 35px;

    .head-operate {
      line-height: 35px;

      font-size: 18px;
      color: #000000;
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 0px;
      // left: 22px;
      vertical-align: text-bottom;

      .head-operate-item {
        cursor: pointer;
        width: 128px;
        height: 35px;
        line-height: 35px;
        display: inline-block;
        vertical-align: middle;
        background: #ffa200;
        border-radius: 5px;
        margin-right: 32px;
        text-align: center;
        color: white;
        .head-operate-img {
          position: relative;
          margin-left: -2px;
          margin-right: 4px;
          width: 19px;
          height: 19px;
        }
      }
      .high-item {
      }
      .head-gohome {
        cursor: pointer;

        margin-right: 45px;
      }
      .head-login {
        cursor: pointer;

        margin-right: 13px;
      }
      .head-register {
        cursor: pointer;

        margin-right: 13px;
        margin-left: 13px;
      }
      .user-icon {
        margin-right: 12px;
        height: auto;
        width: 20px;
      }
      .user-my {
        cursor: pointer;

        margin-right: 36px;
      }
      .user-logout {
        cursor: pointer;
      }
      .head-news-wrap {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin-left: 13px;
        .head-news-icon {
          width: 25px;
          height: auto;
        }
        .head-news-dot {
          position: absolute;
          right: -5px;
          top: 2px;
          width: 11px;
          height: 11px;
          background: #e50808;
          border-radius: 50%;
        }
      }
    }
  }
  .infomation-wrap {
    z-index: 900;
    position: fixed;
    top: 60%;
    right: 100px;
    width: 124px;
    height: 135px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .img-wrap {
      position: relative;
      width: 54px;
      height: 52px;
      margin-bottom: 17px;

      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      .dot {
        position: absolute;
        right: -7px;
        top: -7px;
        width: 15px;
        height: 15px;
        background: #ff0000;
        border-radius: 50%;
      }
    }
    span {
      cursor: pointer;
      font-size: 18px;

      font-weight: 500;
      color: #303030;
      line-height: 20px;
    }
  }
}
</style>
