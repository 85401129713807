<template>
  <div class="home">
    <xchead></xchead>
    <div class="min-height">
      <router-view></router-view>
    </div>
    <xcfooter></xcfooter>
    <!-- <div class="container">
      <div class="row">
        <div class="col-md-6">
          <router-link to="/index">index</router-link>

          <a href="#">a</a>
        </div>
        <div class="col-md-6">
          <router-link to="/other">other</router-link>
          <a href="#">a</a>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import xchead from "@/components/head";
import xcfooter from "@/components/footer";

export default {
  components: {
    xchead,
    xcfooter,
  },
  data() {
    return {
      list: [
        {
          title:
            "学习党的二十大建功立业新时代|崔家集镇开展学习贯彻党的二十大精神主题党日活动",
          id: 1,
          time: "2022-11-20 12:30",
        },
        {
          title: "学李克强主持召开国务院常务会议",
          id: 2,
          time: "2022-11-20 12:30",
        },
        {
          title: "人才同心向党|崔",
          id: 3,
          time: "2022-11-20 12:30",
        },
      ],
      currentPage3: 1,
    };
  },
  created() {
    console.log(this.$, "home");
    this.store.set("user", 0);

    console.log(this.store.get("user"));
  },
  methods: {},
};
</script>

<style lang="scss"></style>
