import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/index/home.vue";
import mainHome from "../views/main/index.vue";
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/show",
    name: "show",
    component: () => import("../views/show/show.vue"),
  },
  {
    path: "/home",
    name: "mainHome",
    component: mainHome,
  },
  {
    path: "/cuijiaji",
    name: "cuijiaji",
    component: () => import("../views/main/cuijiaji.vue"),
  },

  {
    path: "/notice",
    name: "notice",
    component: () => import("../views/main/notice.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/main/search.vue"),
  },
  {
    path: "/service",
    name: "service",
    component: () => import("../views/other/index.vue"),
    redirect: "/service/notification",
    children: [
      {
        path: "/service/notification",
        name: "notification",
        component: () => import("../views/other/notification.vue"),
      },
      {
        path: "/service/article",
        name: "article",
        component: () => import("../views/other/article.vue"),
      },
      {
        path: "/service/article2",
        name: "article2",
        component: () => import("../views/other/article2.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "home",
    component: home,
    redirect: "/home",
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/index/index.vue"),
      },
      // 政务服务
      {
        path: "/governmentServices",
        name: "governmentServicesIndex",
        component: () => import("../views/index/governmentServices/index.vue"),
      },
      {
        path: "/governmentServices/work",
        name: "governmentServicesWork",
        component: () => import("../views/index/governmentServices/work.vue"),
      },
      {
        path: "/governmentServices/questionnaire",
        name: "governmentServicesQuestionnaire",
        component: () =>
          import("../views/index/governmentServices/questionnaire.vue"),
      },
      {
        path: "/governmentServices/questionnaireDetail",
        name: "governmentServicesQuestionnaireDetail",
        component: () =>
          import("../views/index/governmentServices/questionnaireDetail.vue"),
      },
      {
        path: "/governmentServices/policyPublicity",
        name: "policyPublicity",
        component: () =>
          import("../views/index/governmentServices/policyPublicity.vue"),
      },
      // 投资崔家村
      {
        path: "/investment",
        name: "investment",
        component: () => import("../views/index/investment/index.vue"),
      },
      // 特色文化
      {
        path: "/characteristicCulture",
        name: "characteristicCulture",
        component: () =>
          import("../views/index/characteristicCulture/index.vue"),
      },
      // 智慧村庄
      {
        path: "/smartVillage",
        name: "smartVillage",
        component: () => import("../views/index/smartVillage/index.vue"),
      },
      {
        path: "/smartVillage/oneVillage",
        name: "oneVillage",
        component: () => import("../views/index/smartVillage/oneVillage.vue"),
      },
      {
        path: "/smartVillage/oneVillageDetail",
        name: "oneVillageDetail",
        component: () =>
          import("../views/index/smartVillage/oneVillageDetail.vue"),
      },
      {
        path: "/smartVillage/villageControl",
        name: "villageControl",
        component: () =>
          import("../views/index/smartVillage/villageControl.vue"),
      },
      {
        path: "/smartVillage/villageAnnouncement",
        name: "villageAnnouncement",
        component: () =>
          import("../views/index/smartVillage/villageAnnouncement.vue"),
      },
      // 农需农供
      {
        path: "/agriculturalDemandAndSupply",
        name: "agriculturalDemandAndSupply",
        component: () =>
          import("../views/index/agriculturalDemandAndSupply/index.vue"),
      },
      {
        path: "/agriculturalDemandAndSupply/latestRelese",
        name: "latestRelese",
        component: () =>
          import("../views/index/agriculturalDemandAndSupply/latestRelese.vue"),
      },
      {
        path: "/agriculturalDemandAndSupply/agriculturalProducts",
        name: "agriculturalProducts",
        component: () =>
          import(
            "../views/index/agriculturalDemandAndSupply/agriculturalProducts.vue"
          ),
      },
      {
        path: "/agriculturalDemandAndSupply/agriculturalMaterials",
        name: "agriculturalMaterials",
        component: () =>
          import(
            "../views/index/agriculturalDemandAndSupply/agriculturalMaterials.vue"
          ),
      },
      {
        path: "/agriculturalDemandAndSupply/agriculturalMachinery",
        name: "agriculturalMachinery",
        component: () =>
          import(
            "../views/index/agriculturalDemandAndSupply/agriculturalMachinery.vue"
          ),
      },
      {
        path: "/agriculturalDemandAndSupply/landTransfer",
        name: "landTransfer",
        component: () =>
          import("../views/index/agriculturalDemandAndSupply/landTransfer.vue"),
      },
      // 农工供需
      {
        path: "/villagerDemandAndSupply",
        name: "villagerDemandAndSupply",
        component: () =>
          import("../views/index/villagerDemandAndSupply/index.vue"),
      },
      {
        path: "/villagerDemandAndSupply/demandAndSupply",
        name: "demandAndSupply",
        component: () =>
          import("../views/index/villagerDemandAndSupply/demandAndSupply.vue"),
      },
      // 便民生活
      {
        path: "/convenientLife",
        name: "convenientLife",
        component: () => import("../views/index/convenientLife/index.vue"),
      },
      {
        path: "/convenientLife/convenient",
        name: "convenient",
        component: () => import("../views/index/convenientLife/convenient.vue"),
      },
      // 农技学堂
      {
        path: "/agriculturalTechnologySchool",
        name: "agriculturalTechnologySchool",
        component: () =>
          import("../views/index/agriculturalTechnologySchool/index.vue"),
      },
    ],
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/user/home.vue"),
    redirect: "/user/info",
    children: [
      {
        path: "/user/index",
        name: "userIndex",
        component: () => import("../views/user/index.vue"),
      },
      {
        path: "/user/cancel",
        name: "cancel",
        component: () => import("../views/user/cancel.vue"),
      },
      {
        path: "/user/publish",
        name: "myPublish",
        component: () => import("../views/user/myPublish.vue"),
      },
      {
        path: "/user/auth",
        name: "auth",
        component: () => import("../views/user/auth.vue"),
      },
      {
        path: "/user/info",
        name: "info",
        component: () => import("../views/user/info.vue"),
      },
      {
        path: "/user/report",
        name: "myReport",
        component: () => import("../views/user/myReport.vue"),
      },
      {
        path: "/user/collect",
        name: "collect",
        component: () => import("../views/user/myCollect.vue"),
      },
      {
        path: "/user/agroIndustry",
        name: "agroIndustry",
        component: () => import("../views/user/agroIndustry.vue"),
      },
    ],
  },
  {
    path: "/release",
    name: "release",
    component: () => import("../views/release/index.vue"),
    redirect: "/release/offerAdvice",
    children: [
      {
        path: "/release/offerAdvice",
        name: "offerAdvice",
        component: () => import("../views/release/offerAdvice.vue"),
      },
      {
        path: "/release/test",
        name: "releaseTest",
        component: () => import("../views/release/test.vue"),
      },
      {
        path: "/release/disputeReport",
        name: "disputeReport",
        component: () => import("../views/release/disputeReport.vue"),
      },
      {
        path: "/release/agriculturalProductsSAD",
        name: "agriculturalProductsSAD",
        component: () => import("../views/release/agriculturalProductsSAD.vue"),
      },
      {
        path: "/release/agriculturalMaterialsSAD",
        name: "agriculturalMaterialsSAD",
        component: () =>
          import("../views/release/agriculturalMaterialsSAD.vue"),
      },
      {
        path: "/release/agriculturalMachineryService",
        name: "agriculturalMachineryService",
        component: () =>
          import("../views/release/agriculturalMachineryService.vue"),
      },
      {
        path: "/release/agricultureIndustrySAD",
        name: "agricultureIndustrySAD",
        component: () => import("../views/release/agricultureIndustrySAD.vue"),
      },
      {
        path: "/release/landTransfer",
        name: "releaseLandTransfer",
        component: () => import("../views/release/landTransfer.vue"),
      },
    ],
  },

  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/upload",
    name: "upload",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/upload.vue"),
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  console.log("to", to, from);
  if (to.name && to.name.indexOf("article") > -1) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  next();
  // if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
});
export default router;
