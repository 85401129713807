<template>
  <div class="con2" @click="con2 = false" v-show="con2">
    <slide-verify
      @click.native.stop
      class="verify"
      ref="slideblock"
      @again="onAgain"
      @fulfilled="onFulfilled"
      @success="onSuccess"
      @fail="onFail"
      @refresh="onRefresh"
      :imgs="picArray"
      :slider-text="text"
    >
      <!-- :accuracy="accuracy" -->
    </slide-verify>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picArray: [
        require("@/assets/verify/1.jpg"),
        require("@/assets/verify/2.jpg"),
        require("@/assets/verify/3.jpg"),
        require("@/assets/verify/4.jpg"),
        require("@/assets/verify/5.jpg"),
        require("@/assets/verify/6.jpg"),
      ],
      text: "",
      con2: false,
    };
  },
  methods: {
    open() {
      this.con2 = true;
    },
    onFail() {
      console.log("验证不通过");
      this.msg = "";
    },
    onRefresh() {
      console.log("点击了刷新小图标");
      this.msg = "";
    },
    onFulfilled() {
      console.log("刷新成功啦！");
    },
    onAgain() {
      console.log("检测到非人为操作的哦！");
      this.msg = "try again";
      // 刷新
      this.$refs.slideblock.reset();
    },
    handleClick() {
      // 父组件直接可以调用刷新方法
      this.$refs.slideblock.reset();
    },
    onSuccess() {
      console.log("验证通过");
      this.con2 = false;
      this.$emit("successHandler");
    },
  },
};
</script>

<style lang="scss" scoped>
.con2 {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 991;

  .verify {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    ::v-deep .slide-verify-slider {
      border: 0;
      margin-top: -5px;
    }
  }
}
</style>
