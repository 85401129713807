/**
 * axios二次封装
 */

import axios from "axios";
import qs from "qs";
import merge from "lodash/merge";
import store from "@/store";
import router from "@/router";
import { Message, Loading } from "element-ui";

const TOKEN_INVALID = "Token认证失败，请重新登录";
const NETWORK_ERROR = "网络请求异常，请稍后重试";

const newSource = {};
// 跨域请求，允许保存cookie
// axios.defaults.withCredentials = true;
// axios.defaults.headers = {'Content-Type': 'application/json; charset=utf-8'}
// axios.defaults.headers["Content-Type"] =
//   "application/x-www-form-urlencoded; charset=utf-8";

// VUE_APP_BASE_API;
const BASE_URL =
  process.env.NODE_ENV !== "production"
    ? process.env.VUE_APP_BASE_API
    : process.env.VUE_APP_SERVER_URL;

const imgUrl = process.env.VUE_APP_IMG_BASE_URL;
const upload = process.env.VUE_APP_UPLOAD_URL;
// 创建axios实例对象，添加全局配置
const service = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
    // 'content-type': 'multipart/form-data'
  },
});

// 请求拦截
let loading;
service.interceptors.request.use(
  (config) => {
    let showLoading = false;
    if (config.loading === true) {
      showLoading = true;
    }
    if (showLoading) {
      loading = Loading.service({
        fullscreen: true,
        text: config.loadingText || "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    }
    // 请求头带上token
    if (localStorage.getItem("token")) {
      // config.headers.token = localStorage.getItem("token")
    }

    const type = config.method;
    const defaults = {};
    const arrayFormat = config.headers.arrayFormat || "indices";
    if (
      type === "post" &&
      config.headers["Content-Type"] ===
        "application/x-www-form-urlencoded; charset=utf-8"
    ) {
      // post请求参数处理
      config.data = qs.stringify(config.data, {
        allowDots: true,
        arrayFormat: arrayFormat,
      });
      // config.param = config.data;
    } else if (type === "get") {
      // get请求参数处理
      // config.params = qs.stringify(config.params, {
      //   allowDots: true,
      //   arrayFormat: arrayFormat,
      // });
      // config.params = qs.parse(config.params);
      // config.params = merge(defaults, config.params);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
service.interceptors.response.use(
  (res) => {
    if (loading) {
      loading.close();
    }
    const { code, data, msg } = res.data;

    if (code === 200) {
      if (!res.data.success) {
        Message.error({
          showClose: true,
          message: msg,
          duration: 2 * 1000,
        });
        console.error(msg || NETWORK_ERROR);
        return Promise.reject(msg || NETWORK_ERROR);
      } else {
        // if (typeof data == "object") {
        //   for (let key in data) {
        //     var element = data[key];
        //     if (Array.isArray(element)) {
        //       element.map((item) => {
        //         if (item.hasOwnProperty("infoPicture")) {
        //           item.infoPicture = imgUrl + item.infoPicture;
        //         }
        //       });
        //     }
        //   }
        // }

        // console.log("data", data);
        return res.data;
      }
    } else if (code == 401) {
      //认证
      console.error(TOKEN_INVALID);
      Message.error({
        showClose: true,
        message: msg,
        duration: 1200,
      });
      setTimeout(() => {
        router.push("/login");
      }, 1500);
      return Promise.reject(TOKEN_INVALID);
    } else if (code == 504) {
      // 网络错误
      console.error(NETWORK_ERROR);
      Message.error({
        showClose: true,
        message: msg,
        duration: 1200,
      });
      return Promise.reject(NETWORK_ERROR);
    } else {
      Message.error({
        showClose: true,
        message: msg,
        duration: 1200,
      });
      console.error(msg || NETWORK_ERROR);
      return Promise.reject(msg || NETWORK_ERROR);
    }
  },
  (error) => {
    if (loading) {
      loading.close();
    }
    return Promise.reject(error);
  }
);

function request(options) {
  // options.method = options.method || "get";
  // if (options.method.toLowerCase() === "get") {
  //   options.params = options.data;
  // }
  if (option.isCancel) {
    if (typeof newSource[option.isCancel] === "function") {
      newSource[option.isCancel]();
    }
    option.cancelToken = new axios.CancelToken(
      (c) => (newSource[option.isCancel] = c)
    );
  }
  // console.log("requestrequestrequestrequestrequest");
  return service(options);
}

let defaultOptions = {};
["get", "post", "put", "delete", "patch"].forEach((item) => {
  request[item] = (url, params, options = {}) => {
    options = merge(defaultOptions, options);
    if (options.isCancel) {
      if (typeof newSource[options.isCancel] === "function") {
        newSource[options.isCancel]();
      }
      options.cancelToken = new axios.CancelToken(
        (c) => (newSource[options.isCancel] = c)
      );
    }
    // console.log("requestrequestrequestrequestrequest");
    if (item == "get") {
      return service({
        url,
        params,
        method: item,
        ...options,
      });
    } else {
      return service({
        url,
        data: params,
        method: item,
        ...options,
      });
    }
  };
});

function getHttp(url, params, options = {}) {
  options = merge(defaultOptions, options);

  return service({
    url,
    params,
    method: "get",
    ...options,
  });
}
function postHttp(url, data, options = {}) {
  options = merge(defaultOptions, options);
  return service({
    url,
    data,
    method: "post",
    ...options,
  });
}
function install(Vue) {
  Vue.prototype.$http = request;
  Vue.prototype.$getHttp = request["get"];
  Vue.prototype.$postHttp = request["post"];
  Vue.prototype.$baseURL = BASE_URL;
  Vue.prototype.$imgUrl = imgUrl;
  Vue.prototype.$upLoad = upload;
}
export default install;
